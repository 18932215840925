export default {
    afterUpdate: function(chart) {
        let stats = chart.options.drilledTotalStats;

        _.forEach(stats, function(val, key) {
            let elem = document.querySelector('#' + key)
            if (elem) {
                elem.innerText = val
            }
        })
    }
}
