import {Controller} from 'stimulus'

export default class extends Controller {

    static targets = [ "saveIcon", "deleteIcon"]

    connect() {
        this.saveIconEnabled = false
        this.deleteIconEnabled = false
    }

    edit(ev) {
        ev.currentTarget.closest('.widget').classList.add('--editing')
        this.saveIconEnabled = true
        this.deleteIconEnabled = true
    }

    save(ev) {
        ev.currentTarget.closest('.widget').classList.remove('--editing')
        this.saveIconEnabled = false
        this.deleteIconEnabled = false
    }

    set saveIconEnabled(val) {
        this.saveIconTarget.dataset.enabled = val
    }

    set deleteIconEnabled(val) {
        this.deleteIconTarget.dataset.enabled = val
    }
}
