import {Controller} from "stimulus"

const STRIPE_OK = 'stripe-ok'

export default class extends Controller {
    static targets = [ "form", "card", "cardholder", "errors" ]

    connect() {
        this.data.set(STRIPE_OK, '0')
        this.stripe = Stripe(this.data.get("public-key"))
        const elements = this.stripe.elements()
        const style = JSON.parse(this.data.get("style"))

        this.card = elements.create("card", { style: style })
        this.card.mount(this.cardTarget)
        this.card.addEventListener('change', (event) => {
            if (event.error) {
                this.errorsTarget.textContent = event.error.message
            } else {
                this.errorsTarget.textContent = ""
            }
        })
    }

    stripePaymentMethodHandler(method) {
        const hiddenInput = document.createElement("input")

        hiddenInput.setAttribute("type", "hidden")
        hiddenInput.setAttribute("name", "signup[billing][payment_method]")
        hiddenInput.setAttribute("value", method.id)
        this.formTarget.appendChild(hiddenInput)
        this.data.set(STRIPE_OK, '1')

        ujs.fire(this.formTarget, 'submit')
    }

    submit(event) {
        if (this.data.get(STRIPE_OK) === '1') {
            this.data.set(STRIPE_OK, '0')
            return
        }

        ujs.stopEverything(event)

        this.stripe.createPaymentMethod({
            type: 'card',
            card: this.card,
            billing_details: {
                name: this.cardholderTarget.value
            }
        }).then((result) => {
            if (result.error) {
                this.errorsTarget.textContent = result.error.message
            } else {
                this.stripePaymentMethodHandler(result.paymentMethod)
            }
        })
    }

    previous(event) {
        this.data.set(STRIPE_OK, '1')
    }
}
