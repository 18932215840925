import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['accountSearchInput']

    getInfo() {
        const account =  this.accountSearchInputTarget.value
        if (account) {
            const url =  this.accountSearchInputTarget.dataset.url + '/' + account
            ujs.ajax({
                url: url,
                type: 'get',
                dataType: 'script'
            })
        }
    }
}
