import { Controller } from "stimulus"
import moment from 'moment'

function syncValues(targets, value) {
    targets.forEach( (el) => {
        if (el.value != value) {
            el.value = value
        }
    })
}

function syncDisplay(targets, value) {
    targets.forEach( (el) => {
        el.innerHTML = value
    })
}

const calculators = {
    budget: async function() {
        let budget = this.budget

        const cpmUrl = this.data.get('cpmUrl')
        const cpm = await (await fetch(cpmUrl)).json()
        const mpdpdUrl = this.data.get('mpdpdUrl')
        const mpd = await (await fetch(mpdpdUrl)).json()

        let newMetres = cpm ? (budget / cpm) : null
        let newDaysRemaining = (newMetres && mpd && this.numDrills)
            ? Math.ceil((newMetres / (mpd * this.numDrills))) : null

        this.budget = budget
        this.metres = newMetres
        this.daysRemaining = newDaysRemaining
    },

    endDate: async function() {
        let endDate = this.endDate
        let days = Math.ceil(endDate.diff(moment(), 'days')) + 1

        const mpdpdUrl = this.data.get('mpdpdUrl')
        const mpd = await (await fetch(mpdpdUrl)).json()
        const cpmUrl = this.data.get('cpmUrl')
        const cpm = await (await fetch(cpmUrl)).json()

        let newMetres = mpd ? (mpd * this.numDrills * days) : null
        let newBudget = (cpm && newMetres) ? (cpm * newMetres) : null

        this.daysRemaining = days
        this.metres = newMetres
        this.budget = newBudget
    },

    metres: async function() {
        let metres = this.metres

        const cpmUrl = this.data.get('cpmUrl')
        const cpm = await (await fetch(cpmUrl)).json()
        const mpdpdUrl = this.data.get('mpdpdUrl')
        const mpd = await (await fetch(mpdpdUrl)).json()

        let newBudget = cpm ? (cpm * metres) : null
        let newDaysRemaining = (mpd && this.numDrills) ? Math.ceil(metres / (mpd * this.numDrills)) : null

        this.metres = metres
        this.daysRemaining = newDaysRemaining
        this.budget = newBudget
    }
}

export default class extends Controller {
    static targets = [ 'budgetInput', 'endDateInput', 'metresInput', 'drillInput', 'budget', 'metres', 'endDate' ]

    connect() {
        let drills = this.data.get('drills')
        this.numDrills = drills
        this.calculate()
    }

    updateDrills(ev) {
        this.numDrills = ev.target.value
        this.calculate(ev)
    }

    resetValues() {
        this.budget = null
        this.metres = null
        this.daysRemaining = null
    }

    calculate(ev) {
        if (ev) {
            ev.stopPropagation()
            if (!ev.target.checkValidity()) {
                this.resetValues()
                return false
            }
        }

        switch (this.mode) {
            case 'budget':
                calculators.budget.call(this)
                break
            case 'endDate':
                calculators.endDate.call(this)
                break
            case 'metres':
                calculators.metres.call(this)
                break
        }
    }

    get numDrills() {
        return Number.parseFloat(this.drillInputTargets[0].value) || 1
    }

    set numDrills(value) {
        syncValues.call(this, this.drillInputTargets, value)
    }

    get budget() {
        return Number.parseFloat(this.budgetInputTarget.value) || 0
    }

    set budget(value) {
        let display = value != null ? new Intl.NumberFormat(document.documentElement.lang).format(Math.round(value)) : '-'
        syncDisplay.call(this, this.budgetTargets, display)
        syncValues.call(this, this.budgetInputTargets, value ? Math.round(value) : null)
    }

    get endDate() {
        let parsed = moment(this.endDateInputTarget.value)
        return parsed.isValid() ? parsed : moment()
    }

    set daysRemaining(value) {
        let date = value != null ? moment().add(value,'days') : null
        let display = date ? date.format('ll') : '-'
        syncDisplay.call(this, this.endDateTargets, display)
        syncValues.call(this, this.endDateInputTargets, date ? date.format('YYYY-MM-DD') : null)
    }

    get metres() {
        return Number.parseFloat(this.metresInputTarget.value) || 0
    }

    set metres(value) {
        let display = value != null ? new Intl.NumberFormat(document.documentElement.lang).format(Math.round(value)) : '-'
        syncDisplay.call(this, this.metresTargets, display)
        syncValues.call(this, this.metresInputTargets, value ? Math.round(value) : null)
    }

    get mode() {
        let tabber = this.application.getControllerForElementAndIdentifier(this.element, 'tabbed-content')
        return tabber.tab || 'unk'
    }
}
