import Utils from './utils'

export default {
    fontSizeForGaugeLabels: function(chart) {
        return 1.0 * .1 * chart.height
    },
    beforeLayout: function(chart) {
        let canvas = chart.canvas
        let ctx = chart.chart.ctx
        let gaugeConfig = chart.config.options.elements.gaugeConfig
        let fontStyle = gaugeConfig.fontStyle
        let fontSize = Utils.fontSizeToFit(chart, 'XXX,XXXm', chart.width/12, 30, fontStyle)
        let sidePadding = Utils.stringWidth(chart, 'XXX,XXXm', fontSize, fontStyle)
        chart.options.layout.padding = {
            left: sidePadding,
            right: sidePadding,
            top: fontSize,
            bottom: fontSize
        }
    },

    afterDraw: function (chart, options) {
        if (chart.config.options.elements.gaugeConfig) {
            let canvas = chart.canvas
            let ctx = chart.chart.ctx
            var formatNumber = function(num) {
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }

            //Get options from the center object in options
            let gaugeConfig = chart.config.options.elements.gaugeConfig
            let fontStyle = gaugeConfig.fontStyle
            let fontSize = Utils.fontSizeToFit(chart, 'XXX,XXXm', chart.outerRadius / 4, 30, fontStyle)
            let center = Utils.centerOfChartArea(chart)
            ctx.font = `bold ${fontSize}px ${fontStyle}`
            ctx.fillStyle = 'white'

            ctx.textBaseline = 'bottom'
            ctx.textAlign = 'center'
            ctx.fillText(
                gaugeConfig.half || '',
                center.x,
                center.y - chart.outerRadius
            )

            ctx.textBaseline = 'top'
            ctx.textAlign = 'left'

            ctx.fillText(
                gaugeConfig.full || '',
                center.x + chart.outerRadius * Math.cos(chart.options.rotation+chart.options.circumference),
                center.y + chart.outerRadius * Math.sin(chart.options.rotation+chart.options.circumference)
            )

            ctx.textAlign = 'right'
            ctx.fillText(
                gaugeConfig.zero || '',
                center.x + chart.outerRadius * Math.cos(chart.options.rotation),
                center.y + chart.outerRadius * Math.sin(chart.options.rotation)
            )
        }
    }
}
