function buildGradient(ctx, from, to, colorArray, minAlpha) {
    minAlpha = minAlpha ?? 0
    let gradient = ctx.createLinearGradient(0, from, 0, to)
    let stop = `rgba(${colorArray[0]}, ${colorArray[1]}, ${colorArray[2]}, `
    gradient.addColorStop(0, stop + minAlpha + ')')
    gradient.addColorStop(0.5, stop + '1)')
    gradient.addColorStop(1, stop + '1)')

    return gradient
}

function hexToRgb(hex) {
  var res = hex.match(/[a-f0-9]{2}/gi)
  return res && res.length === 3
    ? res.map(function(v) { return parseInt(v, 16) })
    : null
}

export default {
    beforeInit: function(chart) {
        chart.options.tooltips.callbacks.labelColor = function(tooltipItem, chart) {
            let config = chart.config.options['gradient-fill'] || {}
            let isSeparate = config.separate || false
            let dataset = chart.data.datasets[tooltipItem.datasetIndex]
            let itemIndex = tooltipItem.index

            var meta = chart.getDatasetMeta(tooltipItem.datasetIndex);
            var activeElement = meta.data[tooltipItem.index];

            let backgroundColor = Array.isArray(dataset.backgroundColor)
                ? dataset.backgroundColor[Math.min(itemIndex, dataset.backgroundColor.length-1)]
                : dataset.backgroundColor

            var view = activeElement._view;
            return {
                borderColor: view.borderColor,
                backgroundColor: backgroundColor
            }
        }
    },

    beforeDraw: function (chart) {
        chart.data.datasets.forEach(function (dataset, index) {
            const ctx = chart.chart.ctx
            var gradientTop = chart.chartArea.top
            let totalHeight = chart.chartArea.bottom - chart.chartArea.top
            let config = chart.config.options['gradient-fill'] || {}
            let isSeparate = config.separate || false

            let metaObject = chart.data.datasets[index]._meta[chart.id]
            let datasetMinYPt = _.minBy(metaObject.data || null, function (pt) { return pt._model.y })
            if (datasetMinYPt) {
                gradientTop = datasetMinYPt._model.y
            }

            if (metaObject.$filler) {
                let gradient = buildGradient(
                    ctx,
                    chart.chartArea.bottom,
                    gradientTop,
                    dataset.gradient ?? hexToRgb(dataset.backgroundColor)
                )
                metaObject.$filler.el._view.backgroundColor = gradient
            } else if (dataset.type == 'bar') {
                if (!isSeparate) {
                    metaObject.data.forEach(function (item, itemIndex) {
                        let gradient = buildGradient(
                            ctx,
                            chart.chartArea.bottom,
                            gradientTop,
                            dataset.gradient ?? hexToRgb(dataset.backgroundColor)
                        )
                        item._model.backgroundColor = gradient
                    })
                } else {
                    metaObject.data.forEach(function (item, itemIndex) {
                        let hexColor = Array.isArray(dataset.backgroundColor)
                            ? dataset.backgroundColor[Math.min(itemIndex, dataset.backgroundColor.length-1)]
                            : dataset.backgroundColor
                        let rgbColor = hexToRgb(hexColor)
                        if (rgbColor) {
                            let itemGradient = buildGradient(
                                ctx,
                                chart.chartArea.bottom,
                                gradientTop,
                                rgbColor,
                                0.75
                            )
                            item._model.backgroundColor = itemGradient
                        }
                    })
                }
            } else if (dataset.type == 'horizontalBar') {
                metaObject.data.forEach(function (item, itemIndex) {
                    let hexColor = Array.isArray(dataset.backgroundColor)
                        ? dataset.backgroundColor[Math.min(itemIndex, dataset.backgroundColor.length-1)]
                        : dataset.backgroundColor
                    let rgbColor = hexToRgb(hexColor)
                    if (rgbColor) {
                        let itemGradient = buildGradient(
                            ctx,
                            item._model.y + item._model.height/2,
                            item._model.y - item._model.height/2,
                            rgbColor,
                            0.75
                        )
                        item._model.backgroundColor = itemGradient
                    }
                })
            }
        })
    }
}
