import {Controller} from 'stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {

    static targets = [ "widgetsWrapper", "widgetsLibraryWrapper" ]

    connect() {
        startDraggability(this)
    }

    disconnect() {
        endDraggability(this)
    }

    delete(ev) {
        this.widgetsLibraryWrapperTarget.querySelector('.widgets-library-list').append(ev.currentTarget.closest('.widget'))
        showLibraryCard(ev.currentTarget.closest('.widget'))
        processMove(this, ev)
    }

    openWidgetsLibrary() {
        this.widgetsLibraryWrapperTarget.classList.remove("hidden")
    }

    closeWidgetsLibrary(ev) {
        this.widgetsLibraryWrapperTarget.classList.add("hidden")
    }

    dashboardReload(ev) {
        window.location.reload()
    }

    redirectHome(ev) {
        window.location.href = "/"
    }

    get editable() {
        const val = this.data.get('editable')
        return (val == null || val == "1")
    }

    set editable(value) {
        this.data.set('editable', value ? "1" : "0")
        if (!value) {
            this.closeWidgetsLibrary()
            endDraggability(this)
        } else {
            startDraggability(this)
        }
    }
}

function startDraggability(controller) {
    const self = controller
    const widgets = Sortable.create(self.element.querySelector('.dashboard'), getWidgetOptions(self))
    const availableWidgets = Sortable.create(self.element.querySelector('.widgets-library-list'), getAvailableWidgetOptions(self))

    self.widgets = widgets
    self.availableWidgets = availableWidgets
}

function endDraggability(controller) {
    if (controller.widgets) {
        controller.widgets.destroy()
        controller.widgets = null
    }
    if (controller.availableWidgets) {
        controller.availableWidgets.destroy()
        controller.availableWidgets = null
    }
}

function processMove(controller, ev) {
    if (!controller.editable) {
        return
    }

    // Grabs all the widgets in there curent order.
    const widgets = controller.widgetsWrapperTarget.querySelectorAll('.widgets > .widget')
    var fd = new FormData();
    fd.append('_method', 'put')
    _(widgets).each((widget, i) => {
        fd.append(`dashboard[${i}]`, widget.dataset.widgetKey)
    })
    const url = controller.element.dataset.settingsUrl

    ujs.ajax({
        url: url,
        type: 'post',
        dataType: 'script',
        data: fd
    })
}

function getFullWidget(controller, ev) {
    const url = ev.item.dataset.fetchUrl;
    ujs.ajax({
        url: url,
        type: 'get',
        dataType: 'script',
    })
}

function showLibraryCard(widget) {
    const libraryCard = widget.querySelector('.widget-library-item')
    widget.parentNode.replaceChild(libraryCard, widget)
}

function getWidgetOptions(controller) {
    const options = {
        handle: '.grabber',
        onUpdate: function(ev) {
            processMove(controller, ev)
        },
        onAdd: function (ev, originalEv) {
            getFullWidget(controller, ev)
            processMove(controller, ev)
        },
    }
    return {...getDefaultWidgetOptions(), ...options}
}

function getAvailableWidgetOptions(controller) {
    const options = {
        filter: '.empty-placeholder',
        onUnchoose: function(ev) {
            if (ev.to != ev.from && ev.from.classList.contains('widgets-library-list')) {
                ev.item.querySelector('.name').textContent = 'Loading...'
            }
        },
        onAdd: function (ev, originalEv) {
            showLibraryCard(ev.item)
            processMove(controller, ev)
        },
    }
    return {...getDefaultWidgetOptions(), ...options}
}

function getDefaultWidgetOptions() {
    const userAgent = navigator.userAgent
    var forceFallback = false
    if (userAgent.indexOf("Firefox") !== -1 || userAgent.indexOf("Chrome") !== -1) {
        forceFallback = true
    }
    return {
        filter: 'button',
        group: 'widgets',
        animation: '350',
        forceFallback: forceFallback,
        ghostClass: 'u-ghosted',
        chosenClass: 'u-dragging',
    }
}
