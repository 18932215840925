import {Controller} from "stimulus"

function getSelectedTab() {
    let tab = _.find(this.tabTargets, (tab) => {
        return tab.dataset.selected == "1"
    })
    if (tab) {
        return tab.dataset.refId
    }
}

function selectTab(withId) {
    this.tabTargets.forEach((el) => {
        if (el.dataset.refId == withId) {
            el.dataset.selected = "1"
        } else {
            delete el.dataset.selected
        }
    })
    this.tabContainerTargets.forEach((el) => {
        if (el.dataset.id == withId) {
            el.dataset.shown = "1"
        } else {
            delete el.dataset.shown
        }
    })
}

export default class extends Controller {
    static targets = ['tabContainer', 'tab']

    connect() {
        // looks strange, but force the tab content to be displayed
        this.tab = this.tab
    }

    showTab(ev) {
        let tab = ev.target.closest('[data-ref-id]')
        let id = tab && tab.dataset.refId
        if (id) {
            this.tab = id
        }
    }

    get tab() {
        return getSelectedTab.call(this)
    }

    set tab(id) {
        selectTab.call(this, id)
    }
}
