const millisInADay = 24 * 60 * 60 * 1000
const unitToMillisFactors = {
    day: millisInADay,
    week: 7 * millisInADay,
    month: 31 * millisInADay
}

class CustomPanHandler {
    startEvent = null
    endEvent = null
    startTouch = null
    endTouch = null
    isTouching = false
    scroller = null
    startPct = null
    programmaticScroll = false
    panThreshold = 20

    constructor(chart, bounds, updater) {
        this.node = chart.canvas
        this.bounds = bounds
        this.updater = updater
        this.mouseDownHandler = this.onMouseDown.bind(this)
        this.mouseMoveHandler = this.onMouseMove.bind(this)
        this.mouseUpHandler = this.onMouseUp.bind(this)

        this.touchStartHandler = this.onTouchStart.bind(this)
        this.touchMoveHandler = this.onTouchMove.bind(this)
        this.touchEndHandler = this.onTouchEnd.bind(this)

        this.scroller = chart.canvas.parentNode.querySelector('.scroller') ?? null
        this.scrollHandler = this.onScroll.bind(this)

        this.isPanning = false
    }

    bindEvents() {
        this.node.addEventListener('mousedown', this.mouseDownHandler)
        this.node.ownerDocument.addEventListener('mouseup', this.mouseUpHandler)

        this.node.addEventListener('touchstart', this.touchStartHandler)
        this.node.addEventListener('touchend', this.touchEndHandler)

        if (this.scroller) {
            this.scroller.addEventListener('scroll', this.scrollHandler)
        }
    }

    unbindEvents() {
        this.node.removeEventListener('mousedown', this.mouseDownHandler)
        this.node.removeEventListener('mousemove', this.mouseMoveHandler)
        this.node.ownerDocument.removeEventListener('mouseup', this.mouseUpHandler)

        this.node.removeEventListener('touchstart', this.touchStartHandler)
        this.node.removeEventListener('touchend', this.touchEndHandler)

        if (this.scroller) {
            this.scroller.removeEventListener('scroll', this.scrollHandler)
        }
    }

    onMouseDown(ev) {
        this.node.addEventListener('mousemove', this.mouseMoveHandler)
        this.startEvent = ev
        this.endEvent = ev
        this.firstClientX = this.startEvent.clientX
    }

    onMouseMove(ev) {
        this.startEvent = this.endEvent
        this.endEvent = ev
        if (this.absoluteDeltaFromBeginning > this.panThreshold) {
            this.isPanning = true
        }
        this.updater.call()
    }

    onMouseUp(ev) {
        this.node.removeEventListener('mousemove', this.mouseMoveHandler)
        this.startEvent = this.endEvent = null
        this.isPanning = false
        this.firstClientX = null
    }

    onTouchStart(ev) {
        this.isTouching = true
        this.node.addEventListener('touchmove', this.touchMoveHandler)
        this.startTouch = ev.touches[0]
        this.firstClientX = this.startTouch.clientX
    }

    onTouchMove(ev) {
        this.startTouch = this.endTouch
        this.endTouch = ev.touches[0]
        if (this.absoluteDeltaFromBeginning > this.panThreshold) {
            this.isPanning = true
        }
        this.updater.call()
    }

    onTouchEnd(ev) {
        this.isTouching = false
        this.node.removeEventListener('touchmove', this.touchMoveHandler)
        this.startTouch = this.endTouch = null
        this.isPanning = false
        this.firstClientX = null
    }

    onScroll(ev) {
        if (this.programmaticScroll) {
            this.programmaticScroll = false
            return
        }

        this.programmaticScroll = false
        // user used the scrollbar to scroll. adjust delta
        if (this.scroller) {
            this.startPct = this.scroller.scrollLeft / this.scroller.scrollWidth
            this.updater.call()
            this.startPct = null
        }
    }

    clampRange(range) {
        if (range.min < this.bounds.min) {
            let diff = this.bounds.min - range.min
            range.min = this.bounds.min
            range.max = range.max + diff
        } else if (range.max > this.bounds.max) {
            let diff = range.max - this.bounds.max
            range.max = this.bounds.max
            range.min = range.min - diff
        }
    }

    getScrollData(range) {
        let totalRange = this.bounds.max - this.bounds.min
        let shownRange = range.max - range.min
        var data = {
            startPct: 0,
            shownPct: 1
        }

        if (shownRange < totalRange && totalRange > 0) {
            data = {
                startPct: (range.min - this.bounds.min) / totalRange,
                shownPct: shownRange / totalRange
            }
        }

        return data
    }

    adjustScrollerTo(pos) {
        this.programmaticScroll = true
        this.scroller.scrollTo({
            left: pos
        })
    }

    get absoluteDeltaFromBeginning() {
        if (this.isTouching) {
            return this.endTouch ? Math.abs(this.endTouch.clientX  - this.firstClientX) : 0
        }

        return this.endEvent ? Math.abs(this.endEvent.clientX - this.firstClientX) : 0
    }

    get delta() {
        if (this.isTouching) {
            return this.endTouch ? this.endTouch.clientX - this.startTouch.clientX : 0
        }

        return this.endEvent ? this.endEvent.clientX - this.startEvent.clientX : 0
    }

    get calculatedOffset() {
        if (this.startPct === null) {
            return
        }
        return this.bounds.min + this.startPct * (this.bounds.max - this.bounds.min)
    }
}

export default {
    beforeInit: function(chart) {
        chart.$customPan = new CustomPanHandler(chart, {
            min: chart.options.panning.bounds.min,
            max: chart.options.panning.bounds.max
        }, function() {
            chart.update({duration: 0})
        })
        chart.$customPan.bindEvents()
    },

    beforeUpdate: function(chart) {
        if (chart.options.panning.fixRightPad) {
            _.each(chart.scales, function(scale) {
                scale.options.afterFit = function(s) {
                    s.paddingRight = chart.options.panning.fixRightPad
                }
            })
        }
    },

    beforeLayout: function(chart) {
        var chartUnitsToMillisFactor = unitToMillisFactors[chart.options.scales.xAxes[0].time.unit]
        let minTickWidth = chart.options.panning.minTickWidth || 100
        let deltaPixels = chart.$customPan.delta
        let deltaOffset = chartUnitsToMillisFactor * (deltaPixels/minTickWidth)

        let element = document.querySelector(chart.options.panning.selector)
        let width = element.offsetWidth
        var actual = {
            min: chart.options.scales.xAxes[0].ticks.min || chart.$customPan.bounds.min,
            max: chart.options.scales.xAxes[0].ticks.max || chart.$customPan.bounds.max
        }
        if (chart.$customPan.startPct !== null) {
            deltaOffset = actual.min - chart.$customPan.calculatedOffset
        }

        let timespan = actual.max - actual.min
        let timespanThatFits = chartUnitsToMillisFactor * (width/minTickWidth)
        if (timespanThatFits < timespan) {
            actual.min = actual.max - timespanThatFits
            chart.options.scales.xAxes[0].ticks.min = actual.min - deltaOffset
            chart.options.scales.xAxes[0].ticks.max = actual.max - deltaOffset
            chart.$customPan.clampRange(chart.options.scales.xAxes[0].ticks)
        } else if (timespanThatFits > timespan) {
            actual.max = chart.$customPan.bounds.max
            actual.min = actual.max - timespanThatFits
            chart.options.scales.xAxes[0].ticks.min = actual.min
            chart.options.scales.xAxes[0].ticks.max = actual.max
        } else {
            chart.options.scales.xAxes[0].ticks.min = actual.min - deltaOffset
            chart.options.scales.xAxes[0].ticks.max = actual.max - deltaOffset
            chart.$customPan.clampRange(chart.options.scales.xAxes[0].ticks)
        }
    },


    afterDraw: function(chart) {
        let scrollData = chart.$customPan.getScrollData(chart.options.scales.xAxes[0].ticks)
        let scrollBarWidth = chart.width

        let scroller = chart.canvas.parentNode.querySelector('.scroller')
        if (scroller && scroller.children.length > 0) {
            let pane = scroller.children[0]
            let width = scrollBarWidth / scrollData.shownPct
            pane.style.width = width + 'px'
            chart.$customPan.adjustScrollerTo(width * scrollData.startPct)
        }
    },

    destroy: function(chart) {
        if (chart.$customPan) {
            chart.$customPan.unbindEvents()
        }
    }
}
