import {Controller} from "stimulus"
import Popper from "popper.js"

function addEvents() {
    document.addEventListener('hover click', this.handleDocumentEvent)
}

function removeEvents() {
    document.removeEventListener('hover click', this.handleDocumentEvent)
}

function create(button, tooltip, options = '{}') {
    options = Object.assign({
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            }
        ],
    }, JSON.parse(options))
    this._popperInstance = new Popper(button, tooltip, options)
    addEvents.call(this)
}

function destroy() {
    let tooltip = this.templateTarget
    delete tooltip.dataset.show

    removeEvents.call(this)
    if (this._popperInstance) {
        this._popperInstance.destroy();
        this._popperInstance = null;
    }
}

export default class extends Controller {
    static targets = ['template']

    connect() {
        this.handleDocumentEvent = (ev) => {
            if (this.hasTemplateTarget && ev.target !== this.templateTarget) {
                destroy.call(this)
            }
        }
    }

    disconnect() {
        removeEvents.call(this)
    }

    show(ev) {
        if (this.hasTemplateTarget) {
            ev.preventDefault()
            ev.stopPropagation()

            let tooltip = this.templateTarget
            let options = tooltip.dataset.popoverOptions
            tooltip.dataset.show = true
            create.call(this, ev.target, tooltip, options)
        }
    }

    hide(ev) {
        if (this.hasTemplateTarget) {
            ev.preventDefault()
            ev.stopPropagation()
            destroy.call(this)
        }
    }

    toggle(ev) {
        if (this._popperInstance) {
            this.hide(ev)
        } else {
            this.show(ev)
        }
    }
}
