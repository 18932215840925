import Utils from './utils'

function drawOverlayLine(ix, ctx, label, value, onRight, startX, startY, config) {
    ctx.fillStyle = 'white'
    ctx.textBaseline = 'middle'
    var valStr = value

    if (value === false) {
        valStr = label
        label = ''
    } else {
        valStr = value + 'm'
    }

    if (onRight) {
        ctx.textAlign = 'left'
        ctx.font = `bold ${config.fontSize}px ubuntu`
        let width = Utils.stringWidth(this, label + '  ')
        ctx.fillText(label, startX, startY + (ix)*config.lineHeight)

        ctx.font = `300 ${config.fontSize}px ubuntu`
        ctx.fillText(valStr, startX + width, startY + ix*config.lineHeight)
    } else {
        ctx.textAlign = 'right'
        ctx.font = `300 ${config.fontSize}px ubuntu`
        let width = Utils.stringWidth(this, '  ' + valStr)
        ctx.fillText(valStr, startX, startY + (ix)*config.lineHeight)

        ctx.font = `bold ${config.fontSize}px ubuntu`
        ctx.fillText(label, startX - width, startY + ix*config.lineHeight)
    }
}

function roundRect(
    ctx,
    x,
    y,
    width,
    height,
    radius = 11
  ) {
    if (typeof radius === 'number') {
      radius = {tl: radius, tr: radius, br: radius, bl: radius};
    } else {
      radius = {...{tl: 0, tr: 0, br: 0, bl: 0}, ...radius};
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
}

function drawTargetLine(chart, config) {
    let style = 'rgba(255, 255, 255, 1)'
    let yValue = chart.scales.cumulative.getPixelForValue(config.targetValue)
    let ctx = chart.ctx
    ctx.lineWidth = 1
    ctx.beginPath()
    ctx.setLineDash([5, 5])
    ctx.moveTo(chart.chartArea.left, yValue)
    ctx.lineTo(chart.width, yValue)
    ctx.strokeStyle = style
    ctx.stroke()


    ctx.restore()

    const txt = config.targetValue + 'm'
    let font = `10px ubuntu`
    ctx.font = font
    let paddingX = 15
    let paddingY = 8

    var width = ctx.measureText(txt).width;
    ctx.textBaseline = "top"
    ctx.textAlign = 'right'
    ctx.fillStyle = "rgba(255, 255, 255, .9)"

    const x = chart.chartArea.right - 30
    const y = yValue
    const height = parseInt(font, 10)
    roundRect(ctx, x - width, y - height, width + paddingX, height + paddingY)

    ctx.fillStyle = "#000";
    ctx.textAlign = 'right'
    ctx.fillText(txt, x + paddingX / 2, y + paddingY / 2 - height);
}

export default {
    beforeInit: function(chart) {
        chart.options.timelineHover = _.assign({
            lineEndSize: 4,
            fontStyle: 'white',
            fontSize: 12,
            lineHeight: 14,
            totalLabelText: 'Total'
        }, chart.options.timelineHover || {})
    },

    afterDraw: function(chart) {
        if (chart.active && chart.active.length > 0) {
            let meta = chart.getDatasetMeta(chart.active[0]._datasetIndex)
            let view = meta.data[chart.active[0]._index]._view

            if (view.x < chart.chartArea.left) {
                return
            }

            if (chart.$customPan && (chart.$customPan.isPanning)) {
                return
            }

            let ctx = chart.ctx;

            let config = chart.options.timelineHover

            ctx.save()
            ctx.strokeStyle = 'white'
            ctx.setLineDash([5,5])
            ctx.moveTo(view.x, chart.chartArea.bottom)
            ctx.lineTo(view.x, chart.chartArea.top + 2*config.lineEndSize)
            ctx.stroke()

            ctx.beginPath()
            ctx.ellipse(view.x, chart.chartArea.top+config.lineEndSize, config.lineEndSize, config.lineEndSize, 0, 0, 2*Math.PI)
            ctx.setLineDash([])
            ctx.stroke()

            ctx.beginPath()
            ctx.ellipse(view.x, chart.chartArea.top+config.lineEndSize, config.lineEndSize/2, config.lineEndSize/2, 0, 0, 2*Math.PI)
            ctx.fillStyle = 'white'
            ctx.fill()

            let center = Utils.centerOfChartArea(chart)
            let onRight = (center.x > view.x)
            let textX = view.x + (onRight ? 2 : -2) * config.lineEndSize
            let textY = chart.chartArea.top + config.lineEndSize
            var total = 0

            chart.active.forEach((p, ix) => {
                let d = chart.data.datasets[p._datasetIndex]
                var label = d.label
                var value = d.data[p._index].y || 0
                if (d.dataLabels && d.dataLabels[p._index]) {
                    label = d.dataLabels[p._index]
                    value = false
                }
                drawOverlayLine.call(chart, ix, ctx, label, Math.round(value), onRight, textX, textY, config)
                total += value
            })

            let lines = chart.active.length;

            if (config.shiftTargetValue) {
                config.targetValue = config.shiftTargetValue * chart.data.datasets.reduce((sum, data) => sum + !Object.values(data._meta)[0].hidden, 0);
            }

            if (config.targetLabelText && config.targetValue) {
                drawOverlayLine.call(
                    chart,
                    lines,
                    ctx,
                    config.targetLabelText, Math.round(config.targetValue),
                    onRight, textX, textY,
                    config
                )
                lines++
                drawTargetLine(chart, config)
            }

            if (config.totalLabelText) {
                drawOverlayLine.call(
                    chart,
                    lines,
                    ctx,
                    config.totalLabelText, Math.round(total),
                    onRight, textX, textY,
                    config
                )
                lines++
            }


            if (config.varianceLabelText && config.targetValue) {
                const variance = total - config.targetValue
                drawOverlayLine.call(
                    chart,
                    lines,
                    ctx,
                    config.varianceLabelText, ((variance < 0) ? '' : '+') + Math.round(variance),
                    onRight, textX, textY,
                    config
                )
                lines++
            }

            ctx.restore()
        }
    }
}
