import { Controller } from "stimulus"
import { Dropzone } from "dropzone"

export default class extends Controller {
    static targets = ['zone', 'template', 'container']

    connect() {

        let opts = this.options
        if (opts.withForm && opts.maxFiles == 1) {       //TODO: check to see if it's form too
            opts.init = function() {
                let myDropzone = this
                let form = this.element.closest('form')

                if(!form) {
                    return
                }

                myDropzone.options.url = form.action
                form.querySelector('input[type="submit"]').addEventListener("click", function(e) {
                    e.preventDefault();
                    e.stopPropagation();

                    if(myDropzone.getQueuedFiles().length > 0) {
                        myDropzone.processQueue()
                    } else {
                        form.submit()
                    }
                });

                this.on('addedfile', (file) => {
                    if (this.files.length > opts.maxFiles) {
                        this.removeFile(this.files[0])
                    }
                }),
                this.on('sending', (data, xhr, formData) => {
                    let invoiceFormData = new FormData(form)
                    for (var invData of invoiceFormData.entries()) {
                        formData.append(invData[0], invData[1]);
                    }

                }),
                this.on('success', (file, fileInfo) => {
                    location.reload()
                })
            }
        } else {
            opts.init = function() {
                this.on('sending', (file, xhr, formData) => {
                    if(this.token) {
                        formData.append('_token', this.token)
                    }
                }),
                this.on('success', (file, fileInfo) => {
                    let parentId = fileInfo.parent_id
                    let event = new CustomEvent('refresh-list', { detail: {load_document_list: true, parent_id: parentId} })
                    window.dispatchEvent(event)
                    window.closeDialogs()
                })
            }
        }

        this.dropzone = new Dropzone(this.zoneTarget, opts)

    }

    setActionUrl() {
        // If the form action is not defined on init, we can update it here
        let dropzoneForm = this.dropzone.element.querySelector('a.dropzone-url')
        this.dropzone.options.url = this.dropzone.element.action || dropzoneForm.href
    }

    setActionToken() {
        // If dropzone is not using a form, the csrf token needs to be set
        let dropZoneTokenElem = this.dropzone.element.querySelector('input[name="_token"]')
        this.dropzone.token = dropZoneTokenElem.value
    }

    get options() {
        let str = this.data.get('options') || '{}'
        let opts = JSON.parse(str) || {}

        if (this.data.get('withForm') == "1") {
            opts = _.merge(opts, {
                autoProcessQueue: false,
                uploadMultiple: true,
                parallelUploads: 100,
                url: 'NOTUSED',
                withForm: true
            })
        }

        return _.merge(
            {
                dictDefaultMessage: this.data.get('uploadMessage') || '+',
                previewTemplate: (this.hasTemplateTarget ? this.templateTarget.innerHTML : '<div style="display:none"></div>'),
                previewsContainer: (this.hasContainerTarget ? this.containerTarget : null)
            },
            opts
        )
    }
}
