import {Controller} from "stimulus"
import Choices from "choices.js";

export default class extends Controller {

    connect() {
        const options = JSON.parse(this.element.dataset.choicesOptions ?? '{}' )

        // Select options are empty by default, and passed through choices-select-options.
        // Loop through the options and add them to the select element.
        const selectOptions = JSON.parse(this.element.dataset.choicesSelectOptions ?? '{}' )
        selectOptions.forEach((data) => {
            let option = document.createElement("option")
            option.text = data.name
            option.value = data.id
            this.element.add(option)
        });
        new Choices(this.element, {
            ...{
                removeItemButton: true,
                placeholder: true,
                placeholderValue: 'All',
            },
            ...options
        });
    }

    destroy() {
        Choices.destroy();
    }
}
