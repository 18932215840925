import { Controller } from "stimulus"

import ChartJsPlugins from "../chart_plugins"

function createChart() {
    let config = this.chartConfig
    if (this.chart) {
        this.chart.destroy()
    }
    window.requestAnimationFrame(() => {
        this.chart = new window.Chart( this.canvasTarget, config )
    })
}

function loadChart() {
    const filters = document.getElementById('report-filters')
    let params = this.chartParams
    if (filters) {
        params['start_date'] = filters.querySelector('[name="startdate"]').value
        params['end_date'] = filters.querySelector('[name="enddate"]').value
        params['drills'] = _.map(filters.querySelector('[name="drills[]"]').options, function(o) { return o.value })
        params['holes'] = _.map(filters.querySelector('[name="holes[]"]').options, function(o) { return o.value })
    }

    this.loaded = false

    ujs.ajax({
        url: this.data.get('updateUrl'),
        type: 'get',
        dataType: 'script',
        data: new URLSearchParams(params).toString(),
        success: data => {
            this.chartConfig = data
            createChart.call(this)
            this.loaded = true
        },
        error: error => {
            if (error.data.redirectUrl) {
                window.location.href = error.data.redirectUrl
            }
        }
    })
}

export default class extends Controller {
    static targets = [ 'canvas', 'filter' ]

    connect() {
        if (this.loaded) {
            return
        }

        if (this.data.has('config')) {
            createChart.call(this)
            this.loaded = true
        } else if (this.data.has('updateUrl')) {
            loadChart.call(this)
        }
    }

    updateChartData() {
        let filter = this.filterTarget.value
        let key = this.filterTarget.name
        var params = {}
        params[key] = filter
        this.chartParams = params
        loadChart.call(this)
    }

    reload() {
        loadChart.call(this)
    }

    get chartConfig() {
        let config = JSON.parse( this.data.get('config') )
        if (config.hasOwnProperty('plugins')) {
            config.plugins = ChartJsPlugins.remap(config.plugins)
        }
        return config
    }

    set chartConfig(obj) {
        this.data.set('config', JSON.stringify(obj))
    }

    get chartParams() {
        return this.data.has('params') ? JSON.parse(this.data.get('params')) : {}
    }

    set chartParams(obj) {
        return this.data.set('params', JSON.stringify(obj))
    }

    get loaded() {
        return this.data.get('loaded') == '1'
    }

    set loaded(flag) {
        if (flag) {
            this.data.set('loaded', '1')
        } else {
            this.data.delete('loaded')
        }
    }
}
