export default class {

    static centerOfChartArea(chart) {
        let area = chart.chartArea

        return {
            x: ((area.left + area.right) / 2) + (chart.offsetX || 0),
            y: ((area.top + area.bottom) / 2) + (chart.offsetY || 0)
        }
    }

    static fontSizeToFit(chart, str, radius, guess, fontStyle) {
        let ctx = chart.ctx

        ctx.font = guess + 'px ' + fontStyle
        let width = ctx.measureText(str).width
        let fitRadius = Math.sqrt(guess * guess + width * width) / 2

        return Math.floor((guess * radius) / fitRadius)
    }

    static stringWidth(chart, str, fontSize = null, fontStyle = null) {
        let ctx = chart.ctx

        if (fontSize) {
            ctx.font = fontSize + 'px ' + fontStyle
        }
        return ctx.measureText(str).width
    }
}
