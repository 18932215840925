import {Controller} from "stimulus"
import moment from 'moment'

export default class extends Controller {

    static targets = ['form', 'formAmount', 'fields', 'reviewBox', 'reviews', 'nextDialog']

    showForm() {
        this.formTarget.style.display = 'block';
        if (this.hasFormAmountTarget)
            this.formAmountTarget.style.display = 'none';
        this.reviewBoxTarget.style.display = 'none';

        document.getElementById('modal-title').textContent = this.formTarget.dataset.title;
    }

    showFormAmount() {
        this.formTarget.style.display = 'none';
        this.formAmountTarget.style.display = 'block';
        this.reviewBoxTarget.style.display = 'none';
        document.getElementById('modal-title').textContent = this.formTarget.dataset.title;
    }

    showReview(elem) {
        if (!document.forms['form-welcome'].reportValidity())
            return false;

        this.formTarget.style.display = 'none';
        if (this.hasFormAmountTarget)
            this.formAmountTarget.style.display = 'none';
        this.reviewBoxTarget.style.display = 'block';

        document.getElementById('modal-title').textContent = this.reviewBoxTarget.dataset.title;
        var prefix = elem.currentTarget.dataset.prefix;

        this.reviewsTargets.forEach((el, i) => {
            var fieldName =  el.dataset.field
            let result = this.fieldsTargets.find(elem => elem.id==prefix + fieldName);
            if (result) {
                let value = result.value;
                if (result.dataset.format == 'date') {
                    value = moment(value).format('ll');
                }
                el.textContent = value
            }

          })
    }

    showNextDialog() {
        window.showDialogHTMLContent(this.nextDialogTarget.innerHTML.trim())
    }
}
