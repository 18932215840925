import {Controller} from "stimulus"

export default class extends Controller {

    static targets = [ "demo", "demoInfo", "drills", "metres" ]

    toggleEdit(ev) {
        this.demoInfoTarget.toggleAttribute('data-show')
    }

    showParams(ev) {
        document.querySelectorAll('.demo-loader-parameters').forEach((params) => {
            params.removeAttribute('data-show')
        })
        this.demoInfoTarget.setAttribute('data-show', true)
    }

    delete(ev) {
        // If a contract is deleted, then we should make sure that the "+ Add contract" button is re-enabled.
        document.querySelector('.welcome-wizard.--sample .add-contract').dataset.disabled = false
        this.demoTarget.remove()
    }
}
