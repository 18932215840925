import {Controller} from "stimulus"

export default class extends Controller {

    leavingPage(ev) {
        if (this.isPageDirty) {
            // This can be any text. Browsers all have their own text that can't
            // be changed. Also, you can't just return a string. You must set
            // the ev.returnValue and return that.
            ev.returnValue = ''
            return ev.returnValue
        }
    }

    submittingForm(ev) {
        this.isPageDirty = false
    }

    get isPageDirty() {
        return document.body.dataset.dirty === "1"
    }

    set isPageDirty(value) {
        document.body.dataset.dirty = (value ? "1" : "0")
    }
}
