import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['endDate', 'addExtraBtn']

    connect() {
        if (this.hasEndDateTarget) {
            let endDateInput = this.endDateTarget
            if (endDateInput) {
                this.endDate = endDateInput.value
            }
        }
    }

    showInvoice(ev) {
        if (ev.target.tagName != 'DIV') {
            return false
        }
        window.location.href = ev.currentTarget.closest('li').dataset.url
    }

    editExtra(ev) {
        if ((ev.currentTarget.dataset.editing == "1") || (ev.target.closest('[form="invoice"]') != null)) {
            return
        }

        ev.preventDefault()
        ev.stopPropagation()
        ujs.ajax({
            url: ev.currentTarget.dataset.url,
            type: 'get',
            dataType: 'script'
        })
    }

    updateDefaultExtraDate(ev) {
        let date = ev.currentTarget.value
        if (date) {
            this.endDate = date
        }
    }

    deleteExtra(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        if (confirm(ev.currentTarget.dataset.confirmMessage)) {
            ujs.ajax({
                url: ev.currentTarget.dataset.url,
                type: 'DELETE',
                dataType: 'script'
            })
        }
    }

    set endDate(val) {
        let btn = this.addExtraBtnTarget
        if (btn) {
            btn.dataset.params = JSON.stringify({
                date: val
            })
        }
    }
}
