import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        this.element.addEventListener('end', function(ev) {
            updateRanks(ev)
        })
    }

    addNewSection(ev) {
        const newSectionTemplate = document.querySelector('#section-list-template ul')
        const newSection = newSectionTemplate.cloneNode(true)
        const newSectionItem = newSection.children[0]
        const sections = document.querySelector('#sections')

        sections.insertBefore(newSection, null)

        let sectionsList = document.querySelectorAll('#sections ul.sa-section-list')
        let index = (sectionsList.length > 1)? sectionsList.length + 1 : 0

        newSection.id = 'section_' + index
        updateAttributesIndexes(newSection, 'site_audit_sections_attributes', index)

        let inputs = newSection.getElementsByTagName('input')
        enableInputs(inputs)

        // Set rank
        inputs[0].value = sectionsList.length
    }

    addNewQuestion(ev) {
        const newQuestionTemplate = document.querySelector('#section-list-items-template li.question')
        const newQuestion = newQuestionTemplate.cloneNode(true);

        const section = ev.currentTarget.parentNode
        const mainSection = section.parentNode.parentNode
        const sectionItems = [...section.children]
        const sectionQuestions = sectionItems.filter(item => item.classList.contains('question'))
        const lastItem = sectionItems[sectionItems.length - 1]

        section.insertBefore(newQuestion, lastItem)

        let mainSectionId = getIndexFromId(mainSection.id)
        updateAttributesIndexes(newQuestion, 'site_audit_sections_attributes', mainSectionId)
        updateAttributesIndexes(newQuestion, 'site_audit_questions_attributes', (sectionQuestions.length))

        // enable new question inputs
        let inputs = newQuestion.getElementsByTagName('input')
        enableInputs(inputs)

        // Set rank
        let rankInput = [...inputs].filter(input => input.name.indexOf('rank') !== -1)
        rankInput[0].value = sectionQuestions.length + 1
    }

    clearRow(ev) {
        let target = ev.currentTarget
        let item = target.closest('li')
        let sections = document.querySelector('#sections')

        if(sections.classList.contains('editable')) {
            let input = item.querySelector('input.delete')
            if(input) {
                input.value = 1
                item.style.display = "none"
            } else {
                item.remove()
            }
        } else {
            item.remove()
        }

    }

    toggleCheckbox(ev) {
        ev.preventDefault()
        ev.stopImmediatePropagation()
        const checkbox = ev.currentTarget.querySelector('input[type="checkbox"]')
        const question = checkbox.closest('li.section-question')
        const sectionHeader = getPreviousSibling(question, '.section-header')
        const sectionCheckbox = sectionHeader.querySelector('input[type="checkbox"]')

        // If the checkbox is unchecked, meaning we want to enable it, loop
        // through the other questions in the section to see if we should  check
        // the entire section.
        var sectionFlag = false
        if (!checkbox.checked) {
            sectionFlag = true
            const questions = document.querySelectorAll(`li:not(.always-present).section-${sectionHeader.dataset.sectionName}-item`)
            questions.forEach((question) => {
                const checkbox = question.querySelector('input[type="checkbox"]')
                if (!checkbox.value)
                    sectionFlag = false
            })
        }

        checkbox.value = !checkbox.checked
        checkbox.checked = !checkbox.checked
        sectionCheckbox.checked = sectionFlag
        sectionCheckbox.value = sectionFlag
    }

    toggleSectionCheckbox(ev) {
        ev.preventDefault()
        ev.stopImmediatePropagation()
        const checkbox = ev.currentTarget.querySelector('input[type="checkbox"]')
        setSectionCheckboxes(checkbox, !checkbox.checked)
    }

    toggleCheckbox(ev) {
        ev.preventDefault()
        ev.stopImmediatePropagation()
        const checkbox = ev.currentTarget.querySelector('input[type="checkbox"]')
        const question = checkbox.closest('li.section-question')
        const sectionHeader = getPreviousSibling(question, '.section-header')

        checkbox.value = (!checkbox.checked)? 1 : 0
        checkbox.checked = (!checkbox.checked)? 1 : 0

        if(sectionHeader) {
            const sectionCheckbox = sectionHeader.querySelector('input[type="checkbox"]')

            // If the checkbox is unchecked, meaning we want to enable it, loop
            // through the other questions in the section to see if we should  check
            // the entire section.
            var sectionFlag = false
            if (!checkbox.checked) {
                sectionFlag = true
                const questions = document.querySelectorAll(`li:not(.always-present).section-${sectionHeader.dataset.sectionName}-item`)
                questions.forEach((question) => {
                    const checkbox = question.querySelector('input[type="checkbox"]')
                    if (!checkbox.value)
                        sectionFlag = false
                })
            }

            sectionCheckbox.checked = sectionFlag
            sectionCheckbox.value = sectionFlag
        }

    }

    toggleSectionCheckbox(ev) {
        ev.preventDefault()
        ev.stopImmediatePropagation()
        const checkbox = ev.currentTarget.querySelector('input[type="checkbox"]')
        setSectionCheckboxes(checkbox, !checkbox.checked)
    }
}

function enableInputs(elements) {
    [...elements].forEach(function (element) {
        element.disabled = false;
    })
}

function updateAttributesIndexes(parent, attrs, index) {
    const elements = parent.querySelectorAll('input, button, label')

    let search =  ''
    let replace = ''

    elements.forEach(function (element) {
        if (element.hasAttribute('id')) {
            search = `_` + `${attrs}` + `__`
            replace = `_` + `${attrs}` + `_` + `${index}`
            element.id = element.id.replace(search, replace)
        }
        if (element.hasAttribute('name')) {
            search = `[` + `${attrs}` + `]` + `[#]`
            replace = `[` + `${attrs}` + `]` + `[${index}]`
            element.name = element.name.replace(search, replace)
        }
        if (element.hasAttribute('for')) {
            element.htmlFor = element.htmlFor.replace(search, replace)
        }
    })
}

function updateItemAttributes(item, oldIndex, itemCount) {
    let elements = item.querySelectorAll('input, button, label')
    let search =  ''
    let replace = ''
    let index = itemCount + 1

    elements.forEach(function (element) {
        let attrs = 'site_audit_questions_attributes'
        if (element.hasAttribute('id')) {
            search = `_` + `${attrs}` + `_${oldIndex}`
            replace = `_` + `${attrs}` + `_` + `${index}`
            element.id = element.id.replace(search, replace)
        }
        if (element.hasAttribute('name')) {
            search = `[` + `${attrs}` + `]` + `[${oldIndex}]`
            replace = `[` + `${attrs}` + `]` + `[${index}]`
            element.name = element.name.replace(search, replace)
        }
    })
}

function updateRanks(ev) {
    const type = ev.target.getAttribute('data-sortable-list-group')

    let fromSection = ev.from.closest('ul.sa-section-list')
    let toSection = ev.to.closest('ul.sa-section-list')
    let fromSectionId = getIndexFromId(fromSection.id)
    let toSectionId = getIndexFromId(toSection.id)

    if(type == 'questions' && fromSectionId !== toSectionId) {
        updateSection(fromSectionId, toSectionId, ev.item)
    }

    if( type == 'questions') {
        let sectionItems = ev.to.children
        let sectionQuestions = [...sectionItems].filter(item => item.classList.contains('question'))

        updateItemAttributes(ev.item, ev.oldIndex, sectionQuestions.length)

        sectionQuestions.forEach(function(q, index) {
            let inputFields = q.querySelectorAll('input')
            let rank = [...inputFields].filter(field => field.id.includes('rank'))
            rank[0].value = index
        })
    }

    if (type == 'sections') {
        let categories = document.querySelectorAll('#sections li.category')
        categories.forEach(function(cat, index) {
            let inputFields = cat.querySelectorAll('input')
            let rank = [...inputFields].filter(field => field.id.includes('rank'))
            rank[0].value = index
        })
    }

}

function updateSection(fromId, toId, section) {
    const searchText = `site_audit_template[site_audit_sections_attributes][${fromId}]`
    const replaceText = `site_audit_template[site_audit_sections_attributes][${toId}]`
    const sectionId = getIndexFromId(section.parentNode.id)

    let sectionItems = section.querySelectorAll('input')
    sectionItems.forEach(function(item, index) {
        item.name = item.name.replace(searchText, replaceText)
        item.id = item.id.replace(searchText, replaceText)

        if(item.id.indexOf('site_audit_section_id') !== -1) {
            item.value = sectionId
        }
    })
}

function getIndexFromId(id) {
    const regex = new RegExp("\\d+")
    return parseInt(id.match(regex))
}

function setSectionCheckboxes(sectionCheckbox, state) {
    sectionCheckbox.value = state
    sectionCheckbox.checked = state
    const sectionHeader = sectionCheckbox.closest('.section-header')
    const questions = document.querySelectorAll(`li:not(.always-present).section-${sectionHeader.dataset.sectionName}-question`)
    questions.forEach((question) => {
        const checkbox = question.querySelector('input[type="checkbox"]')
        checkbox.value = state
        checkbox.checked = state
    })
}
