import {Controller} from "stimulus"

export default class extends Controller {

    editAnswer(ev) {
        ujs.ajax({
            url: ev.currentTarget.dataset.editUrl,
            type: 'get',
            dataType: 'script'
        })
    }

    submit(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        let params = '?site_audit_answer[id]=' + ev.currentTarget.dataset.id
        ujs.ajax({
            url: ev.currentTarget.dataset.editUrl + params,
            type: 'get',
            dataType: 'script'
        })
    }

    deleteSiteAudit(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        if (confirm(ev.currentTarget.dataset.confirmMessage)) {
            ujs.ajax({
                url: ev.currentTarget.dataset.deleteUrl,
                type: 'delete',
                dataType: 'script',
                error: () => {
                    alert(ev.currentTarget.dataset.errorMessage)
                }
            })
        }
    }

}
