import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['dropzone', 'extras', 'notes', 'notesIcon', 'notesInput', 'photos', 'photosUploaded']

    start(ev) {
        stopAllPropagation(ev)
        // This is a brand new site audit wizard, so remove any fieldsets that might still be on the page.
        const fieldsets = document.querySelectorAll('#wizard-form #site-audit-fieldsets fieldset')
        fieldsets.forEach(e => e.parentNode.removeChild(e));
        let data = ev.currentTarget.dataset
        const params = '?drill=' + data.drill + '&date=' + data.date
        ujs.ajax({
            url: this.element.dataset.siteAuditStartUrl + params,
            type: 'get',
            dataType: 'script'
        })
    }

    openNotes(ev) {
        stopAllPropagation(ev)
        this.notesTarget.classList.add('--opened')
        this.notesIconTarget.classList.add('--hidden')
        this.notesInputTarget.classList.remove('--hidden')
        updateExtrasGrid(this)
    }

    openPhotos(ev) {
        this.photosTarget.classList.add('--opened')
        updateExtrasGrid(this)
    }


    closeNotes(ev) {
        stopAllPropagation(ev)
        ev.currentTarget.classList.remove('--opened')
        this.notesTarget.classList.remove('--opened')
        this.notesIconTarget.classList.remove('--hidden')
        this.notesInputTarget.classList.add('--hidden')
        this.notesInputTarget.querySelector('textarea').value = ''
        // Close the grid if both sections are closed.
        updateExtrasGrid(this, !this.photosTarget.classList.contains('--opened'))
    }

    closePhotos(ev) {
        stopAllPropagation(ev)
        ev.currentTarget.classList.remove('--opened')
        this.photosTarget.classList.remove('--opened')
        this.photosTarget.querySelectorAll('.dz-remove').forEach((remove) => { remove.click() })
        // Close the grid if both sections are closed.
        updateExtrasGrid(this, !this.notesTarget.classList.contains('--opened'))
    }

    close(ev) {
        if (!confirm(ev.currentTarget.dataset.confirmMessage)) {
            stopAllPropagation(ev)
        }
    }

    getFieldsets(ev) {
        const formId = ev.currentTarget.dataset.formId
        const form = document.getElementById(formId)
        return new FormData(form)
    }
}

function stopAllPropagation(ev) {
    ev.preventDefault()
    ev.stopPropagation()
}

function updateExtrasGrid(controller, close = false) {
    if (close) {
        controller.extrasTarget.classList.remove('--opened')
    } else {
        controller.extrasTarget.classList.add('--opened')
    }
}
