import {Controller} from "stimulus"

export default class extends Controller {

    static targets = ['url']

    copyUrlToClipboard() {
        var copyText = this.urlTarget;

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");
    }
}
