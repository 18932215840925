function resetLabel(text) {
    let label = document.querySelector('.metrics.timesheet-status .label')
    label.className = 'label'
    label.innerHTML = text
}

export default {
    beforeDraw: function(chart) {
        if (chart.active && chart.active.length > 0) {
            chart.config.options.onHover = (event) => {
                var item = chart.getElementAtEvent(event);
                if (event.type === 'mouseout') {
                    const text = chart.config.options.defaultLabel || "Timesheets"
                    resetLabel.call(this, text)
                }
                if (item.length) {
                    let label = document.querySelector('.metrics.timesheet-status .label')
                    const classes = chart.config.options.modifierClasses
                    const index = item[0]._index
                    label.innerHTML = item[0]._view.label
                    label.className = 'label' + ' ' + classes[index]
                }
            }
        }
    }
}
