import {Controller} from 'stimulus'
import Sortable from 'sortablejs';
import ujs from '@rails/ujs';

export default class extends Controller {

    connect() {
        const self = this
        const sortable = Sortable.create(this.element, {
            group: this.data.get('group') ?? 'sortable-list',
            filter: '[data-role="list-action-buttons"]',
            handle: '.grabber',
            forceFallback: true,
            ghostClass: 'u-ghosted',
            chosenClass: 'u-dragging',
            direction: 'vertical',
            onMove: function(ev) {
                if (ev.related && ev.related.dataset.role == "list-action-buttons") {
                    // -1 = inserts before target, false = cancels
                    return -1;
                }
            },
            onEnd: function(ev) {
                processMove(self, ev)
            }
        })

        this.sortable = sortable;
    }
}

function processMove(controller, ev) {
    if (ev.newIndex != ev.oldIndex) {
        const url = ev.item.dataset.rankUrl;
        if (url) {
            var fd = new FormData();
            fd.append('_method', 'put')
            fd.append('rank', ev.newIndex)
            ujs.ajax({
                url: url,
                type: 'post',
                dataType: 'script',
                data: fd
            })
        } else {
            window.dispatchEvent(ev)
        }
    }
}
