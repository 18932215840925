import {Controller} from "stimulus"

export default class extends Controller {

    reset() {
        const errorBox = this.errorBox
        const form = this.form

        errorBox && (errorBox.innerHTML = '')
        form && form.reset()
    }

    get errorBox() {
        return this.element.querySelector('.error')
    }

    get form() {
        return this.element.querySelector('form')
    }
}
