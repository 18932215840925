
import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['contracts', 'contractName', 'selectedContractsName']

    selectMultiple(event) {
        var is_multi_selecting = this.data.get('multi-select')
        this.data.set('multi-select', is_multi_selecting == '0' ? '1' : '0')

        // clean currency and contracts when cancelling select multiple
        if (is_multi_selecting == '0') {
            this.cleanAfterCancel()
        }
    }

    cleanAfterCancel() {
        this.contracts = []
        this.currency = ""
        this.contractsName = []
        document.getElementById('contract-list').dataset.currency = this.currency
        document.getElementById('contract-list').dataset.contracts = this.contracts
        this.selectedContractsNameTarget.innerHTML = ""
        this.contractsTargets.forEach(function(el) {
            el.disabled = false
            el.checked = false
        })
    }

    selectAll(event) {
        let selectAllCheckbox = event.currentTarget.checked
        var self = this
        this.contractsTargets.forEach(function(el) {
            if (!el.disabled) {
                el.checked = selectAllCheckbox
                self.handleContractSelect(el)
            } else {
                event.currentTarget.checked = false
            }
        })
    }

    createContractGroup(event) {
        if (!this.contracts) {
            return false;
        }
        event.preventDefault()
        ujs.ajax({
            url: event.currentTarget.dataset.url,
            type: 'get',
            dataType: 'script'
        })
    }

    contractSelect(event) {
       this.handleContractSelect(event.currentTarget)
    }

    handleContractSelect(elem) {
        if (!this.contracts) {
            this.contracts = document.getElementById('contract-list').dataset.contracts ? document.getElementById('contract-list').dataset.contracts.split(',') : []
        }
        if (!this.currency) {
            this.currency = document.getElementById('contract-list').dataset.currency != '' ? document.getElementById('contract-list').dataset.currency : elem.dataset.currency
        }

        this.contractsName = this.contractsName ? this.contractsName : [];

        // block to select from different currencies
        if (this.currency != elem.dataset.currency) {
            elem.checked = false
            return false;
        }

        if (elem.checked) {
            this.contracts.push(elem.value)
            if (this.hasSelectedContractsNameTarget) {
                this.contractsName.push(elem.dataset.name)
            }

        } else {
            this.contracts = this.contracts.filter(function(el){
                return el != elem.value;
            });

            let idx = this.contractsName.findIndex(el => el == elem.dataset.name);
            this.contractsName.splice(idx, 1);

            if (this.contracts.length == 0) {
                this.currency = ""
                if (this.hasSelectedContractsNameTarget)
                    this.selectedContractsNameTarget.innerHTML = ""
            }
        }

        document.getElementById('contract-list').dataset.currency = this.currency
        document.getElementById('contract-list').dataset.contracts = this.contracts
        if (this.hasSelectedContractsNameTarget)
            this.selectedContractsNameTarget.innerHTML = this.contractsName.join(', ')
    }

    saveContractGroups(event) {
        event.preventDefault()
        if (!this.contracts && document.getElementById('contract-list').dataset.contracts) {
            this.contracts = document.getElementById('contract-list').dataset.contracts.split(',')
        }
        if (!this.contracts || this.contracts.length == 0) {
            return;
        }

        let data = new FormData()
        data.set('action', event.currentTarget.name)

        this.contracts.forEach(function(contract) {
            data.append('contract_id[]', contract)
        })

        if (this.hasContractNameTarget) {
            data.set('name', this.contractNameTarget.value)
        }

        ujs.ajax({
            url: event.currentTarget.dataset.url,
            type: 'post',
            data: data,
            dataType: 'script'
        })
    }

}
