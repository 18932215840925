import {Controller} from "stimulus"
import Flatpickr from "flatpickr"

export default class extends Controller {

    connect() {
        Flatpickr(this.element, {
            altInput: true,
            altFormat: 'M d, Y',
            dateFormat: "Y-m-d",
            altInputClass: 'flatpickr-input',
            minDate: this.element.dataset.minDate,
        });
    }

    destroy() {
        Flatpickr.destroy();
    }
}
