let ujs = require('@rails/ujs')

let ajaxStateBehaviourSelector = '[data-behavior~="ajax-state"]'

var toggleAjaxState = function(ev) {
    if (ev.type === 'ajax:send') {
        ev.target.dataset.ajaxState = 'pending'
    } else {
        let latch = ev.target.dataset.latchAjaxStateOn
        if (!latch || latch !== ev.type) {
            delete ev.target.dataset.ajaxState
        }
    }
}

ujs.delegate(document, ajaxStateBehaviourSelector, "ajax:send", toggleAjaxState);
ujs.delegate(document, ajaxStateBehaviourSelector, "ajax:success", toggleAjaxState);
ujs.delegate(document, ajaxStateBehaviourSelector, "ajax:error", toggleAjaxState);
