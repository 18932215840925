require('./datadog');
require('./domutils.js')

document.addEventListener('click', function(ev) {
    document.querySelectorAll('[data-behavior~="collapse-on-clickoutside"]').forEach(function(el) {
        if (el != ev.target && !el.contains(ev.target)) {
            let overEl = el.closest('[data-collapsed]')
            if (overEl) {
                overEl.dataset.collapsed = 'true';
            }
        }
    });
});
