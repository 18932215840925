export default {
    beforeLayout: function(chart) {
        let canvas = chart.canvas
        let pct = chart.options.elements.percentagePadding || 0.05
        let pad = pct * Math.min(chart.width, chart.height)
        chart.options.layout.padding = {
            left: pad,
            right: pad,
            top: pad,
            bottom: pad
        }

    }
}
