import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = [ 'section' ]

    visit(ev) {
        if (this.data.get('enabled') == "1") {
            ev.currentTarget.dataset.remote = "1"
            this.selectTimesheet(ev.currentTarget.closest('[data-timesheet-id]'))
        } else {
            delete ev.currentTarget.dataset.remote
        }
    }

    selectTimesheet(element) {
        if (!element) {
            return
        }
        let parent = element.parentNode
        if (!parent) {
            return
        }

        // Hide all other timesheets for this drill.
        parent.querySelectorAll('[data-timesheet-id]').forEach((el) => {
            delete el.dataset.selected
        })

        element.dataset.selected = "1"
        parent.dataset.selected = "1"
    }

    deselectTimesheet(ev) {
        let id = ev.currentTarget.dataset.timesheetId
        if (id) {
            this.deselectTimesheetById(id)
        }
    }

    deselectTimesheetById(id) {
        let element = this.element.querySelector('[data-timesheet-id="' + id + '"]')
        if (element) {
            delete element.dataset.selected
            delete element.parentElement.dataset.selected
            let card = element.closest('[data-role~="drill-activity-card"]')
            if (card) {
                let view = card.querySelector('[data-role~="drill-activity-view"]')
                if (view) {
                    view.innerHTML = ''
                }
            }
        }
    }
}

