import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
    }

    addNewRow() {
        // Get the last new item.
        const newItems = document.querySelectorAll('.new-list-item')
        const newItem = newItems.item(newItems.length - 1).cloneNode(true)
        const index = getIndexFromId(newItems.item(newItems.length - 1).id)

        updatePropertyIndexes(newItem, index)

        newItem.id = `new-list-item-${index + 1}`
        // The {null} argument is needed to place at the end of the list.
        newItems[0].parentNode.insertBefore(newItem, null)
    }

    clearRow(ev) {
        // Don't delete the row if there is only one left.
        const newItems = document.querySelectorAll('.new-list-item')
        if (newItems.length <= 1) {
            return
        }

        const index = getIndexFromId(ev.target.id)
        const row = document.querySelector(`#new-list-item-${index}`)
        row.remove()
    }
}

function updatePropertyIndexes(parent, index) {
    const search = `[${index}]`
    const replace = `[${index + 1}]`
    const elements = parent.querySelectorAll('input, button, label')
    elements.forEach(function (element) {
        if (element.hasAttribute('id'))
            element.id = element.id.replace(search, replace)
        if (element.hasAttribute('name'))
            element.name = element.name.replace(search, replace)
        if (element.hasAttribute('for'))
            element.htmlFor = element.htmlFor.replace(search, replace)
        element.value = ''
    })
}

function getIndexFromId(id) {
    const regex = new RegExp("\\d+")
    return parseInt(id.match(regex))
}
