function pluginConfig(chart) {
    return _.merge({
        fontSize: 12,
        valueFont: 'bold 20px teko',
        pad: 5,
        fontStyle: 'ubuntu',
        color: 'white'
    }, chart.options.cpm_overlay)
}

export default {
    beforeLayout: function(chart) {
        let config = pluginConfig(chart)
        chart.options.layout.padding = {
            bottom: config.fontSize + config.pad
        }
    },

    afterDraw: function(chart) {
        let config = pluginConfig(chart)
        let ctx = chart.ctx

        ctx.textAlign = 'left'
        ctx.textBaseline = 'top'
        let fontSize = config.fontSize
        let fontStyle = config.fontStyle
        let valueFont = config.valueFont
        ctx.font = fontSize + "px " + fontStyle
        ctx.fillStyle = config.color

        ctx.fillText(config.startLabel, chart.chartArea.left, chart.chartArea.bottom + config.pad)

        ctx.textAlign = 'right'
        ctx.fillText(config.endLabel, chart.chartArea.right, chart.chartArea.bottom + config.pad)

        ctx.font = valueFont
        ctx.fillText(config.valueLabel, chart.chartArea.right, chart.chartArea.top + config.pad)
    }
}
