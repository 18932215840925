import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ 'filter' ]

    connect() {
        this.load()
    }

    load() {
        let filter = this.hasFilterTarget ? this.filterTarget.name + '=' + this.filterTarget.value : ''

        ujs.ajax({
            url: this.data.get('url'),
            type: 'get',
            data: filter,
            dataType: 'script'
        })
    }
}
