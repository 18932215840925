let ujs = require('@rails/ujs')

let submitsOnChangeBehaviourSelector = '[data-behavior~="submits-on-change"]'

var submitOnChange = function(ev) {
    const form = ev.target.closest('form')

    if (form != null) {
        ujs.fire(form, 'submit')
    }
}

ujs.delegate(document, submitsOnChangeBehaviourSelector, "change", submitOnChange);
