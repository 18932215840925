import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum }  from '@datadog/browser-rum'

(function (logKey) {
    if (logKey) {
        datadogLogs.init({
          clientToken: logKey,
          datacenter: 'us',
          forwardErrorsToLogs: true,
          sampleRate: 100
        })
    }
})(
    _.result(document.querySelector('meta[name="datadog-key"]'), 'content')
);

(function (appId, token) {
    if (appId && token) {
        datadogRum.init({
            applicationId: appId,
            clientToken: token,
            datacenter: 'us'
        })
    }
})(
    _.result(document.querySelector('meta[name="datadog-rum-app"]'), 'content'),
    _.result(document.querySelector('meta[name="datadog-rum-token"]'), 'content')
);
