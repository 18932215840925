require('./bootstrap');
require('./globals');
require('./behaviours');
require('./icons');
require('./timezone');
const ujs = require('@rails/ujs');

import ChartJsPlugins from "./chart_plugins"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

ujs.start()
const application = Application.start()
if (window) {
    window._application = application
    window.ujs = ujs
}

const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))


ChartJsPlugins.registerFromContext(require.context("./chart_plugins", true, /\.js$/))
