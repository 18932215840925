function pluginNameFrom(key) {
    var name = (key.match(/^(?:\.\/)?(.+)(?:[_-]plugin\..+?)$/) || [])[1]
    if (name) {
        return name.replace(/_/g, "-").replace(/\//g, "--")
    }
}

function nameAndPlugin(context, key) {
    let name = pluginNameFrom(key)
    if (name) {
        return {
            name: name,
            plugin: context(key).default
        }
    }
}

export default class ChartJsPlugins {
    static plugins = {}

    static remap(plugins) {
        return plugins.map(str => {
            let plugin = this.plugins[str]
            if (!plugin) {
                alert('Missing plugin ' + str)
            }
            return plugin
        })
    }

    static registerFromContext(context) {
        context.keys()
            .map(key => { return nameAndPlugin(context, key) })
            .filter(obj => { return obj })
            .forEach(obj => {
                if (obj) {
                    this.plugins[obj.name] = obj.plugin
                }
            })
    }
}
