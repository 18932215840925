import {Controller} from "stimulus"

export default class extends Controller {

    connect() {}

    deleteRow(ev) {
        let pricedType = ev.currentTarget.dataset.pricedType
        let row = ev.currentTarget.closest(`.${pricedType}-price`)
        row.remove()
    }

    addNewRow(ev) {
        let button = ev.currentTarget.closest('.add-new')
        let pricedType = ev.currentTarget.dataset.pricedType
        let rows = document.querySelectorAll(`.price-list__dialog tr.${pricedType}-price`)
        let clone = rows[0].cloneNode(true)
        updatePropertyIndexes(clone, rows.length - 1)
        button.parentNode.insertBefore(clone, button)
    }

    updateInputs(node, count) {
        let inputs = node.getElementsByTagName('input')
        let index = 0
        for (index; index < inputs.length; ++index) {
            inputs[index].value = ''
            inputs[index].name = ''
        }
    }

}

function updatePropertyIndexes(parent, index) {
    const search = '[0]'
    const replace = `[${index + 1}]`
    const elements = parent.querySelectorAll('input')
    elements.forEach(function (element) {
        element.value = ''
        element.name = element.name.replace(search, replace)
    })
}
