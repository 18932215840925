
export default {
    beforeDraw: function(chart) {
        if ((!chart.options || !chart.options.dom_update)) {
            return;
        }
        if (chart.options.dom_update.toggle) {
            chart.options.dom_update.toggle.forEach((data) => {
                const el = chart.canvas.parentElement.parentElement.querySelector(`[data-target="${data.target}"]`)
                if (!el) {
                    return
                }
                const className = el.dataset && el.dataset.class;
                if (!className) {
                    return
                }
                el.classList.toggle(className, data.value)
            })
        }
        if (chart.options.dom_update.html) {
            chart.options.dom_update.html.forEach((data) => {
                const el = chart.canvas.parentElement.parentElement.querySelector(`[data-target="${data.target}"]`)
                if (!el) {
                    return
                }
                el.innerHTML = data.value
            })
        }
    }
}
