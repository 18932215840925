import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['focus', 'link', 'selectMultiple', 'selectTitle']

    connect() {
        this.segmentNames = findSegments(this.element)

        if (this.data.get('forest') !== "" || this.data.get('loaded') == "1") {
            this.forest = JSON.parse(this.data.get('forest'))
            autoSelectSingleItems.call(this)
        }
    }

    update(ev) {
        let segmentName  = ev.detail.segment
        if(segmentName && segmentName == 'file') {
            fillList.call(this, segmentName, ev.detail.data)
        } else {
            this.forest = ev.detail.data
            this.data.set('forest', JSON.stringify(this.forest))
            this.data.set('loaded', '1')
            autoSelectSingleItems.call(this)
        }
    }

    updateItem(item) {
        let selectedItem = getItemByIdFrom.call(this, this.forest, item.parent_id)
        if(selectedItem && selectedItem.items) {
            selectedItem.items.forEach( function(forestItem, index) {
                if(forestItem.id == item.id) {
                    selectedItem.items[index].name = item.name
                }
            })
        }
        let ix = 0
        this.selectSegmentItemById(ix, item.parent_id)
    }

    addItem(item) {
        let selectedItem = getItemByIdFrom.call(this, this.forest, item.parent_id)
        selectedItem.items.push(item)

        let ix = 0
        this.selectSegmentItemById(ix, item.parent_id)
    }

    selectSegmentItemById(index, id) {
        if (index < 0 || index >= this.segmentNames.length) {
            return
        }

        this.selectedPath = _.slice(this.selectedPath, 0, index).concat(id)
        let segmentName = this.segmentNames[index]
        let listItem = this.element.querySelector('[data-segment-id="' + segmentName + '"] [data-item-id="' + id + '"]')
        this.selectOnlyItem(listItem, segmentName)

        if (index+1 < this.segmentNames.length) {
            fillList.call(this, this.segmentNames[index+1], contentsOfLastList.call(this))
            if (this.data.get('change-headers') == "1") {
                let item = getSelectedItem.call(this)
                let itemName = (item)? item.name : ''
                setListHeader.call(this, this.segmentNames[index+1], itemName)
            }
            updateLinks.call(this)
        }
        _.range(this.selectedPath.length+1, this.segmentNames.length, 1).forEach((ix) => this.clearList(ix))
    }

    clearList(index) {
        fillList.call(this, this.segmentNames[index], [])
    }

    selectItem(ev) {

        let listItem = ev.target.closest('[data-item-id]')
        if (!listItem) {
            return
        }

        let segmentName = listItem.closest('[data-segment-id]').dataset.segmentId
        if(ev.target.closest('span.delete-icon') !== null) {
            ev.preventDefault()
            this.delete(listItem)
            return
        }

        if(ev.target.closest('.edit-icon') !== null) {
            return
        }

        if (!listItem || !segmentName) {
            return
        }

        if(segmentName == 'folder') {
            let elem = this.element.closest('article[data-controller="forest-loader"]')
            let controller = this.application.getControllerForElementAndIdentifier(elem, "forest-loader")
            let parms = { detail: {load_document_list: true, parent_id: listItem.dataset.itemId} }
            controller.loadDocumentData(parms)
        }

        let ix = _.indexOf(this.segmentNames, segmentName)
        this.selectSegmentItemById(ix, parseInt(listItem.dataset.itemId))

        setListHeaderActionButtons.call(this, ix)
    }

    deleteItem(ev) {

        ev.preventDefault()

        let elem = ev.target.closest('button')
        let listItem = null
        if(elem.classList.contains('delete-mobile-button')) {
            let itemId = elem.dataset.itemId
            listItem = this.element.querySelector('[data-item-id="' + itemId + '"]')
        }

        listItem = ev.target.closest('[data-item-id]')
        if (!listItem) {
            return
        }
        this.delete(listItem)
    }

    delete(item) {
        var _this = this

        let segmentId = item.closest('[data-segment-id]').dataset.segmentId
        let delBtn = item.querySelector('span.delete-icon')
        let delUrl = delBtn.dataset.delUrl

        if (confirm(delBtn.dataset.confirmMessage)) {
            ujs.ajax({
                url: delUrl,
                type: 'post',
                dataType: 'script',
                success: function(resp) {
                    if(resp.error) {
                        alert(resp.error)
                        return
                    }
                    if(segmentId == 'single-file') {
                        deleteSingleFile.call(_this, item, resp)
                    } else {
                        deleteFileFromFolder.call(_this, item, resp)
                    }
                }
            })
        }
    }

    goBack(ev) {
        let currentPath = this.selectedPath
        this.selectedPath = _.slice(currentPath, 0, currentPath.length - 1)
    }

    selectOnlyItem(elem, list_type) {
        this.element.querySelectorAll('[data-segment-id="' + list_type + '"] li').forEach(function(el) {
            el.classList[el == elem ? 'add' : 'remove']('selected')
        })
    }

    get selectedPath() {
        return JSON.parse(this.data.get('selectedPath') || '[]')
    }

    set selectedPath(val) {
        this.data.set('selectedPath', JSON.stringify(val))
        if (this.hasFocusTarget) {
            this.focusTarget.dataset.focus = this.segmentNames[val.length]
        } else {
            this.element.dataset.focus = this.segmentNames[val.length]
        }
    }
}

function deleteSingleFile(item, resp) {
    let elem = this.element.closest('[data-controller="forest-loader"]')
    let controller = this.application.getControllerForElementAndIdentifier(elem, "forest-loader")
    let parms = { detail: {load_document_list: true, parent_id: resp.id} }
    controller.loadDocumentData(parms)

    deselectItemById.call(this, parseInt(item.dataset.itemId))
}

function deleteFileFromFolder(item, resp) {
    let itemId = parseInt(item.dataset.itemId)
    let selList = item.closest('ul.selection-list')
    let segmentId = selList ? selList.dataset.segmentId : 'single-file'
    let container = getItemByIdFrom.call(this, this.forest, resp.id)
    if(container && container.items) {
        container.items = container.items.filter(function(item) {
            return item.id !== itemId
        })
    }
    window.removeElement(item)
    deselectItemById.call(this, itemId)
}

function deselectItemById(id) {
    let path = this.selectedPath
    let ix = _.indexOf(path, id)
    if (ix != -1) {
        this.selectedPath = path.slice(0, ix)
    }
}

function capitalize(str) {
    return str.replace(/^./, (m) => m.toUpperCase())
}

function autoSelectSingleItems() {
    fillList.call(this, this.segmentNames[0], this.forest)
    _.range(0, this.segmentNames.length-1).forEach((ix) => {
        var list = contentsOfLastList.call(this)
        if (list.length == 1) {
            if (this.hasFocusTarget) {
                this.focusTarget.dataset.effectiveRoot = this.segmentNames[ix]
            }
            this.selectSegmentItemById(ix, list[0].id)
        }
    })
}

function getSelectedItem(depth) {
    let maxDepth = this.selectedPath.length
    depth = (_.isUndefined(depth) || depth===null) ? maxDepth : depth
    if (depth > maxDepth) {
        return null
    }
    var item = null
    var list = this.forest
    _.range(0, depth).forEach((ix) => {
        item = getItemByIdFrom(list, this.selectedPath[ix])
        if (item && maxDepth > ix) {
            list = item.items || []
        }
    })
    return item
}

function contentsOfLastList() {
    let depth = this.selectedPath.length
    var list = this.forest
    _.range(0, depth).forEach((ix) => {
        if (depth > ix) {
            let item = getItemByIdFrom(list, this.selectedPath[ix])
            if(item)
                list = item.items || []
        }
    })
    return list
}

function fillList(list_type, items) {
    var fill = ''
    var remote = (this.data.get('remote-links') == "1")

    if(!items) {
        return
    }

    let delIcon = this.element.querySelector('div.delete-icon')
    let edtIcon = this.element.querySelector('div.edit-icon')
    let folderIcon = this.element.querySelector('div.folder-icon')
    let fileIcon = this.element.querySelector('div.file-icon')
    let checkIcon = this.element.querySelector('div.check-icon')
    let contracts = []
    let currency = ""
    let tooltipText = ""
    if (checkIcon && list_type == 'contract') {
        contracts = (document.getElementById('contract-list').dataset.contracts).split(',');
        currency = document.getElementById('contract-list').dataset.currency;
        tooltipText = document.getElementById('contract-list').dataset.tooltip;
        document.getElementsByClassName('contract-selection')[0].dataset.disabled = 'true'
    }
    let selectAll = 'checked'

    items.forEach(function(item) {
        fill += '<li class="form-like" data-item-id="' + item.id + '">'
        if (item.url) {
            fill += '<a class="file-list-item"' + (remote ? 'data-remote="true" ':'') + 'href="' + item.url + '">'
            if(fileIcon) {
                fill += '<div class="icon-small">' + fileIcon.innerHTML + '</div>'
            }
            fill += '<div class="file-name-link">' + item.name  + '</div>' + deleteItemButton(list_type, item, delIcon) +'</a>'
        } else {
            if(folderIcon) {
                fill += '<div class="icon-small">' + folderIcon.innerHTML + '</div>'
            }
            fill += '<button class="--unassuming">' + item.name + ' ' +
                    editItemButton(list_type, item, edtIcon) +
                    deleteItemButton(list_type, item, delIcon) +
                '</button>'
        }

        if (checkIcon && list_type == 'contract') {
            let isChecked = ""
            if (contracts.includes((item.id).toString())) {
                isChecked = "checked"
            } else {
                selectAll = ""
            }

            let isDisabled = (currency && currency != item.currency) ? 'disabled' : ""
            document.getElementsByClassName('contract-selection')[0].dataset.disabled = isDisabled == 'disabled' ? "true" : "false"
            let tooltipClass = isDisabled ? 'tooltip' : ''
            fill += `<div class="check ${tooltipClass}">
                <input type="checkbox" data-target="contract-groups.contracts" id=${item.id} value=${item.id} data-name="${item.name}" data-currency=${item.currency} data-action="click->contract-groups#contractSelect" ${isChecked} ${isDisabled}>
                <label for="${item.id}"> </label>
                <label for="${item.id}">${item.name}</label>
                <span class="icon">${checkIcon.innerHTML}</span>
                <span class="tooltiptext">${tooltipText}</span>
                </div>`
        }
        fill += '</li>'
    })

    if (list_type == 'contract' && items.length > 0) {
        this.selectMultipleTarget.disabled = false
        let currentSelectTitle = this.selectTitleTarget.innerHTML
        this.selectTitleTarget.innerHTML = this.selectTitleTarget.dataset.text
        this.selectTitleTarget.dataset.text = currentSelectTitle
        document.getElementById('select_all').checked = selectAll
    } else {
        if (this.hasSelectMultipleTarget) {
            this.selectMultipleTarget.disabled = true
        }
    }
    this.element.querySelector('[data-segment-id="' + list_type + '"]').innerHTML = fill
}

function editItemButton(list_type, item, edtIcon) {
    if(list_type == 'folder' && item.delete_folder_url !== '') {
        return '<a href="' + item.edit_folder_url +'" class="icon icon-item edit-icon" data-remote="1">' +
                    edtIcon.innerHTML +
                '</a>'
    } else {
        return ''
    }
}

function deleteItemButton(list_type, item, delIcon) {
    if(list_type == 'folder' && item.delete_folder_url !== '') {
        return '<span class="icon icon-item delete-icon" data-del-url="' + item.delete_folder_url +  '"' +
                    ' data-confirm-message="' + delIcon.dataset.confirmMessage +'"' + '>' +
                    delIcon.innerHTML +
                '</span>'
    } else if (list_type == 'file') {
        return '<span class="icon icon-item delete-icon" data-del-url="' + item.delete_attachment_url + '"' +
                    ' data-confirm-message="' + delIcon.dataset.confirmMessage + '"' + '>' +
                    delIcon.innerHTML +
                '</span>'
    } else {
        return ''
    }
}

function setListHeader(segmentName, title) {
    let titleElement = this.element.querySelector('[data-role~="' + segmentName + '-header"]')
    titleElement && (titleElement.innerHTML = title)
}

function setListHeaderActionButtons(index) {
    let segment = this.segmentNames[index]
    if(segment == 'folder' || segment == 'file') {
        let edtIcon = this.element.querySelector('div.edit-icon')
        let delIcon = this.element.querySelector('div.delete-icon')
        let item = getSelectedItem.call(this)

        if(item) {
            let elemHeaderButtons = this.element.querySelector('[data-role~="' + this.segmentNames[index+1] + '-header"]')
            let htmlString =  '<div class="mobile-action-button --unassuming">' +
                    '<a href="' + item.edit_folder_url + '" data-remote="1"><div class="icon-small">' +
                        edtIcon.innerHTML +
                    '</div></a>' +
                    '<button class="--unassuming delete-mobile-button" data-action="click->contract-selector#deleteItem" data-item-id="' + item.id + '">' +
                        '<span class="icon-small icon-item delete-icon" data-del-url="' + item.delete_folder_url + '"' +
                        ' data-confirm-message="' + delIcon.dataset.confirmMessage + '"' + '>' +
                        delIcon.innerHTML +
                    '</span></button>'
                '</div>'
            appendHTMLAsChild(htmlString, elemHeaderButtons)
        }
    }
}

function updateLinks() {
    let selectionDepth = this.selectedPath.length
    let selectedItems = []

    _.range(0, selectionDepth).forEach((ix) => {
        selectedItems.push(getSelectedItem.call(this, ix+1))
    })

    this.linkTargets.forEach((link) => {
        let depth = link.dataset.depth ?? 1000
        let linkName = link.dataset.linkName ?? null
        var url = null

        if (depth <= selectedItems.length && linkName) {
            if (!selectedItems[depth-1]['is_demo']) {
                url = selectedItems[depth-1][linkName + '_url'] ?? null
            }
        }

        if (url) {
            if(link.href) {
                link.href = url
            } else if(link.action) {
                link.action = url
            } else {
                link.dataset.url = url
            }
            link.disabled = false
            if (linkName == 'new_contract') {
                const hint = document.querySelector('[data-hints-id="contracts-add"]')
                if (hint !== null) {
                    hint.style.visibility = 'visible'
                }
            }
        } else {
            delete link.dataset.url
            link.disabled = true
            if (linkName == 'new_contract') {
                const hint = document.querySelector('[data-hints-id="contracts-add"]')
                if (hint !== null) {
                    hint.style.visibility = 'hidden'
                }
            }
        }
    })
}

function findSegments(el) {
    var segments = []

    el.querySelectorAll('[data-segment-id]').forEach((seg) => {
        segments.push(seg.dataset.segmentId)
    })
    return segments
}

function getItemByIdFrom(list, id) {
    return list.find(function(item) { return item.id == id});
}
