import {Controller} from "stimulus"

export default class extends Controller {

    editItem(ev, params = '') {
        ujs.ajax({
            url: ev.currentTarget.dataset.editUrl + params,
            type: 'get',
            dataType: 'script'
        })
    }

    deleteItem(ev, params = '') {
        ev.preventDefault()
        ev.stopPropagation()
        if (confirm(ev.currentTarget.dataset.confirmMessage)) {
            ujs.ajax({
                url: ev.currentTarget.dataset.deleteUrl + params,
                type: 'get',
                dataType: 'script'
            })
        }
    }

    toggleCheckbox(ev) {
        const checkbox = ev.currentTarget.querySelector('input[type="checkbox"]')

        // If it's checked, send the delete request.
        if (checkbox.checked) {
            ev.preventDefault()
            ev.stopImmediatePropagation()
            ujs.ajax({
                url: ev.currentTarget.dataset.deleteUrl + '?' + ev.currentTarget.dataset.params,
                type: 'get',
                dataType: 'script',
                error: () => {
                    alert('Something went wrong.')
                }
            })
        }
    }
}
