import {Controller} from "stimulus"

export default class extends Controller {

    delete(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        if (confirm(ev.currentTarget.dataset.confirmMessage)) {
            ujs.ajax({
                url: ev.currentTarget.dataset.url,
                type: 'put',
                dataType: 'script'
            })
        }
    }

    destroy(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        if (confirm(ev.currentTarget.dataset.confirmMessage)) {
            ujs.ajax({
                url: ev.currentTarget.dataset.url,
                type: 'delete',
                dataType: 'script'
            })
        }
    }
}
