import Utils from './utils'

export default {
    pinRadiusFactor: 0.1,
    needleLengthFactor: 0.9,

    afterDraw: function(chart) {
        let center = Utils.centerOfChartArea(chart)
        let pinRadius = this.pinRadiusFactor * chart.innerRadius
        let needleWidth = 1.4 * pinRadius
        let needleIndex = (chart.options && chart.options.needle && chart.options.needle.needleIndex) || 0
        let needleAngle = chart.data.datasets[0]._meta[chart.id].data[needleIndex]._view.endAngle
        let ctx = chart.chart.ctx
        ctx.save()
        ctx.beginPath()
        ctx.arc(center.x, center.y, pinRadius, 0, 2 * Math.PI)
        ctx.fillStyle = 'white'
        ctx.fill()
        ctx.translate(center.x, center.y)
        ctx.rotate(needleAngle)
        ctx.moveTo(0, needleWidth / 2)
        ctx.lineTo(0, -needleWidth / 2)
        ctx.lineTo(this.needleLengthFactor * chart.innerRadius, 0)
        ctx.fill()
        ctx.translate(-center.x, -center.y)
        ctx.restore()
    }
}
