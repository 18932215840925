window.getPreviousSibling = function (elem, selector) {
    // Get the previous sibling element
    let sibling = elem.previousElementSibling

    // If there's no selector, return the first sibling
    if (!selector) return sibling

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches(selector)) return sibling
        sibling = sibling.previousElementSibling
    }
}
window.getNextSibling = function (elem, selector) {
    // Get the previous sibling element
    let sibling = elem.nextElementSibling

    // If there's no selector, return the first sibling
    if (!selector) return sibling

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches(selector)) return sibling
        sibling = sibling.nextElementSibling
    }
}

window.convertHTMLToNode = function (htmlString) {
    let tmp = document.createElement('div')
    tmp.innerHTML = htmlString

    return tmp.firstChild
}

window.insertHTMLBefore = function (htmlString, elem) {
    let newNode = convertHTMLToNode(htmlString)
    let parent = elem.parentNode
    parent.insertBefore(newNode, elem)

    return newNode
}

window.appendHTMLAsChild = function (htmlString, elem) {
    let newNode = convertHTMLToNode(htmlString)
    elem.appendChild(newNode)

    return newNode
}

window.swapHTMLBefore = function (htmlString, elem, beforeItem) {
    let newNode = convertHTMLToNode(htmlString)
    if (elem) {
        let parent = elem.parentNode
        parent.replaceChild(newNode, elem)
    } else {
        beforeItem.parentNode.insertBefore(newNode, beforeItem)
    }

    return (elem || beforeItem)
}

window.swapHTMLAt = function (htmlString, elem, alternateParent) {
    let newNode = convertHTMLToNode(htmlString)
    if (elem) {
        let parent = elem.parentNode
        parent.replaceChild(newNode, elem)
    } else {
        alternateParent.appendChild(newNode)
    }

    return (elem || alternateParent)
}

window.removeElement = function (elem) {
    if (elem) {
        elem.parentNode.removeChild(elem)
    }
}

window.removeAll = function (elems) {
    for (let elem of elems) {
        if (elem) {
            elem.parentNode.removeChild(elem);
        }
    }
}

window.copyElementTo = function (elem, destination) {
    if (elem && destination) {
        let clone = elem.cloneNode(true)
        clone.id = clone.id + '-clone'
        destination.appendChild(clone)
    }
}

window.showDialogHTMLContent = function (htmlString) {
    let newNode = convertHTMLToNode(htmlString)
    const controller = window._application.getControllerForElementAndIdentifier(document.body, 'modal')
    controller.openNode(newNode)
}

window.closeDialogs = function () {
    const controller = window._application.getControllerForElementAndIdentifier(document.body, 'modal')
    controller.close()
}

window.pageIsDirty = function () {
    let controller = window._application.getControllerForElementAndIdentifier(document.body, 'dirty')
    if (controller) {
        controller.isPageDirty = true
    }
}

window.highlightNode = function (elem) {
    if (!elem) return;

    const CLASSNAME = 'u-highlight'
    elem.addEventListener('transitionend', function(ev) {
        window.requestAnimationFrame(function() {
            elem.classList.remove(CLASSNAME);
        })
    }, {once: true})
    window.requestAnimationFrame(function() {
        elem.classList.add(CLASSNAME)
    })

    return elem
}

window.convertEscapedHTMLToNode = function (htmlString, search = null, replace = null) {
    if (search === null) {
        search = 'CHILD_INDEX'
    }

    if (replace === null) {
        replace = new Date().getTime()
    }

    htmlString = htmlString.replace(new RegExp(search, "g"), replace)

    let el = new DOMParser().parseFromString(
        '<!doctype html><body>' + htmlString,
        'text/html')

    return this.convertHTMLToNode(el.body.textContent)
}

window.updateLocation = function (url, title, push = false) {
    if (push) {
        history.pushState(null, title, url)
    } else {
        history.replaceState(null, title, url)
    }
    // most browsers ignore title above...
    document.title = title
}

window.scrollToBottom = function (elem) {
    if (!elem) return
    elem.scrollTop = elem.scrollHeight
}

window.showFlashNotification = function (htmlString) {
    let bar = window._application.getControllerForElementAndIdentifier(document.querySelector('[data-controller~="notification-bar"]'), 'notification-bar')
    if (bar) {
        bar.show(htmlString)
    }
}
