import { Controller } from "stimulus"
import { Dropzone } from "dropzone"

export default class extends Controller {
    static targets = [ 'dropzone', 'previewTemplate', 'account' ]

    connect() {
        this.dropzone = new Dropzone(this.dropzoneTarget, this.options)
    }

    addPhotoToForm(fileInfo) {
        const form = document.querySelector(".site-audit-wizard-question.wizard__form form")
        const input = document.createElement("input")

        input.setAttribute("type", "hidden")
        input.setAttribute("name", `site_audit_answer[photos][${fileInfo.document.id}][id]`)
        input.setAttribute("value", fileInfo.document.id)
        input.setAttribute("data-photo-id", fileInfo.document.id)

        form.appendChild(input)
    }

    removePhotoFromForm(photoId) {
        const form = document.querySelector(".site-audit-wizard-question.wizard__form form")
        const input = document.querySelector(`.site-audit-wizard-question.wizard__form form input[data-photo-id="${photoId}"]`)
        form.removeChild(input)
        ujs.ajax({
            url: `/${this.dropzoneTarget.dataset.accountId}/attachments/${photoId}/delete`,
            type: 'post',
            dataType: 'script'
        })
    }

    deletePhoto(ev) {
        ujs.ajax({
            url: ev.currentTarget.dataset.deleteUrl,
            type: 'get',
            dataType: 'script'
        })
    }

    setActionToken() {
        // If dropzone is not using a form, the csrf token needs to be set
        let dropZoneTokenElem = this.dropzone.element.closest('form').querySelector('input[name="_token"]')
        this.dropzone.token = dropZoneTokenElem.value
    }

    get options() {
        const self = this
        const submit = document.querySelector('.site-audit-wizard-question.wizard__form input[type="submit"]')
        return {
            url: this.dropzoneTarget.dataset.submitUrl,
            parallelUploads: 100,
            previewTemplate: this.previewTemplateTarget.innerHTML,
            addRemoveLinks: true,
            dictCancelUpload: '',
            dictRemoveFile: 'x',
            acceptedFiles: "image/*",
            init: function() {
                this.on('sending', (file, xhr, formData) => {
                    if(this.token) {
                        formData.append('_token', this.token)
                    }
                    // Disable submit button
                    submit.disabled = true
                    submit.classList.add('--disabled')
                    submit.value = submit.dataset.uploadingText
                }),
                this.on('removedfile', (file) => {
                    self.removePhotoFromForm(JSON.parse(file.xhr.response).document.id)
                }),
                this.on('success', (file, fileInfo) => {
                    self.addPhotoToForm(fileInfo)
                    // Enable submit button
                    submit.disabled = false
                    submit.classList.remove('--disabled')
                    submit.value = submit.dataset.submitText
                }),
                this.on('error', (file, response) => {
                    // Enable submit button
                    submit.disabled = false
                    submit.classList.remove('--disabled')
                    submit.value = submit.dataset.submitText
                    console.error(response)
                })
            }
        }
    }
}
