import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['autofocus']

    connect() {
        if (this.hasAutofocusTarget) {
            if (this.autofocusTarget.focus) {
                this.autofocusTarget.focus()
            }
            if (this.autofocusTarget.select) {
                this.autofocusTarget.select()
            }
        }
    }
}
