import {Controller} from "stimulus"

export default class extends Controller {

    show(htmlString) {
        this.empty()
        window.appendHTMLAsChild(htmlString, this.element)
        this.element.dataset.showing = '1'
    }

    empty() {
        delete this.element.dataset.showing
        this.element.innerHTML = ''
    }
}
