import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['startTime', 'endTime', 'travelDuration', 'standbyDuration', 'holeId', 'holeName']

    start(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        // This is a brand new timesheet wizard, so remove any fieldsets that might still be on the page.
        const fieldsets = document.querySelectorAll('#wizard-form #fieldsets fieldset')
        fieldsets.forEach(e => e.parentNode.removeChild(e));
        let data = ev.currentTarget.dataset
        const params = '?activity_log=' + data.activityLog + '&drill=' + data.drill + '&shift=' + data.shift + '&date=' + data.date
        ujs.ajax({
            url: this.element.dataset.wizardStartUrl + params,
            type: 'get',
            dataType: 'script'
        })
    }

    nextRecord(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        const formData = this.getFieldsets(ev)

        ujs.ajax({
            url: ev.currentTarget.dataset.nextUrl,
            type: 'post',
            dataType: 'script',
            data: formData,
        })
    }

    copyRecordPreviousTimesheet(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        const formData = this.getFieldsets(ev)

        ujs.ajax({
            url: ev.currentTarget.dataset.url,
            type: 'post',
            dataType: 'script',
            data: formData,
        })
    }

    editDrillingRecord(ev) {
        ev.preventDefault()
        ev.stopImmediatePropagation()
        let params = ev.currentTarget.dataset.params
        const item = ev.currentTarget
        // Need to exclude the current item because we have duplicate <li> for
        // the mobile and desktop items.
        const previousItem = getPreviousSibling(item, '[data-id]:not([data-id="' + item.dataset.id + '"]')
        const nextItem = getNextSibling(item, '[data-id]:not([data-id="' + item.dataset.id + '"]')
        if (previousItem) {
            params += `&drilling_record[previous_end_m]=${previousItem.dataset.endM}`
        }
        if (nextItem) {
            params += `&drilling_record[next_start_m]=${nextItem.dataset.startM}`
        }
        ujs.ajax({
            url: ev.currentTarget.dataset.editUrl + '&' + params,
            type: 'get',
            dataType: 'script'
        })
    }

    deleteRecord(ev) {
        ev.preventDefault()
        ev.stopImmediatePropagation()
        const formData = this.getFieldsets(ev)

        ujs.ajax({
            url: ev.currentTarget.dataset.deleteUrl,
            type: 'post',
            dataType: 'script',
            data: formData,
            error: () => {
                // Set an error that the user can see somehow.
            }
        })
    }

    confirmNoInteraction(ev) {
        if (!ev.currentTarget.dataset.interaction_override && checkFieldsForInteraction()) {
            ev.preventDefault()
            ev.stopImmediatePropagation()
            const popup = document.getElementById('confirm-interaction')
            if (popup.style.display === '' || popup.style.display === "none") {
                popup.style.display = 'block'
            }
        }
    }

    interactionSubmit(ev) {
        document.getElementById('confirm-interaction').style.display = 'none'
        const recordFormSubmit = document.querySelector('.wizard__form > form input[type="submit"]')
        recordFormSubmit.click()
    }

    interactionProceed(ev) {
        let nextRecordButton = document.querySelector('.wizard__form form > footer a')
        nextRecordButton.dataset.interaction_override = true
        nextRecordButton.click()
    }

    submitHoleData(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        if (typeof this.submittingTimesheet === 'undefined' || !this.submittingTimesheet) {
            const formData = this.getFieldsets(ev)
            const timesheetUrl = ev.currentTarget.dataset.timesheetUrl
            const notes = document.getElementById('timesheet[notes]');
            formData.append(notes.name, notes.value)

            this.submittingTimesheet = true
            ujs.ajax({
                url: timesheetUrl,
                type: 'post',
                data: formData,
                success: (response) => {
                    window.location.href = response.timesheetUrl
                },
                error: () => {
                    this.submittingTimesheet = false
                }
            })
        }
    }

    back(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        let params = '?'
        const formData = this.getFieldsets(ev)
        const currentPage = ev.currentTarget.dataset.currentPage
        const holeId = ev.currentTarget.dataset.holeId
        if (currentPage) {
            params += '&current_page=' + currentPage
        }
        if (holeId) {
            params += '&hole_id=' + holeId
        }

        ujs.ajax({
            url: ev.currentTarget.dataset.backUrl + params,
            type: 'post',
            dataType: 'script',
            data: formData,
            error: () => {
                // Set an error that the user can see somehow.
            }
        })
    }

    close(ev) {
        if (confirm(ev.currentTarget.dataset.confirmMessage)) {
            // Remove all fieldsets for that hole if the wizard is closed.
            const holeId = ev.currentTarget.dataset.holeId
            const fieldsets = document.querySelectorAll('fieldset[data-hole-id="' + holeId + '"]')
            fieldsets.forEach(e => e.parentNode.removeChild(e));
        } else {
            ev.preventDefault()
            ev.stopImmediatePropagation()
        }
    }

    getFieldsets(ev) {
        const formId = ev.currentTarget.dataset.formId
        const form = document.getElementById(formId)
        return new FormData(form)
    }
}

function checkFieldsForInteraction() {
    let elements = document.querySelector('.wizard__form form').elements
    for (let i = 0; i < elements.length; i++) {
        // Disregard hidden elements.
        if (elements[i].type === 'hidden') {
            continue
        }
        // Ignore core id since it is auto populated.
        if (elements[i].name === 'drilling_record[core_id]') {
            continue
        }
        // Assume that there was no interaction if the end_m is 1 more than
        // start_m, which is the default (ie end_m defaults to start_m++).
        if (elements[i].name === 'drilling_record[start_m]' && +elements[i].value === +elements[i + 1].value - 1) {
            continue
        }
        if (elements[i].name === 'drilling_record[end_m]' && +elements[i].value === +elements[i - 1].value + 1) {
            continue
        }
        // Ignore start_time since it is auto populated.
        if (elements[i].name === 'crew_hours_record[start_time]') {
            continue
        }
        // Ignore end_time since it is auto populated.
        if (elements[i].name === 'crew_hours_record[end_time]') {
            continue
        }
        // If a person was selected, throw the popup.
        if (elements[i].name === 'crew_hours_record[combo_id]') {
            return elements[i].selectedIndex != 0
        }
        // Materials and services defaults to "1.00". Check for "1.00" and no interaction with the dropdown.
        if ((elements[i].name === 'service_record[quantity]' || elements[i].name === 'material_record[quantity]') && elements[i].value === "1.00" && elements[i - 1].value == "") {
            continue
        }

        if ((elements[i].type === 'time' || elements[i].classList.contains('timeish')) && elements[i].value !== '00:00') {
            return true
        }
        if (elements[i].value > 0) {
            return true
        }
    }
    return false
}
