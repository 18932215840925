function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function tickLabeller(value, index, values) {
    let formatter = new Intl.NumberFormat(document.documentElement.lang)

    return formatter.format(value)
}

function tooltipLabeller(tooltipItem, data) {
    let labels = data.datasets[tooltipItem.datasetIndex].dataLabels
    var label = tooltipItem.datasetIndex.value

    if (Array.isArray(labels) && tooltipItem.index < labels.length) {
        label = labels[tooltipItem.index]
    }

    return label
}

export default {
    beforeInit: function(chart) {
        let xAxes = chart.options.scales.xAxes || []

        xAxes.forEach(function (axis, axisIndex) {
            if (axis.display && axis.type == 'linear') {
                axis.ticks.callback = tickLabeller
            }
        })

        chart.options.tooltips.callbacks.label = tooltipLabeller
    }
}
