import {Controller} from "stimulus"

export default class extends Controller {

    static targets = ['progressBar']

    connect() {
        this.checkProgress()
    }

    disconnect() {
        this.stopProgressBar()
    }

    checkProgress() {
        const url = this.progressBarTarget.dataset.checkProgressUrl
        this.progressCheck = setInterval(() => {
            ujs.ajax({
                url: url,
                type: 'get',
                success: (response) => {
                    // TODO probably need to check/handle if the jobs fails
                    this.updateProgressBar(response)
                }
            })
        }, 3000)
    }

    updateProgressBar(response) {
        this.progressBarTarget.style.width = Math.min((response.progress_now + 5), 100) + "%"
        if (response.progress_now >= 50 && response.output) {
            const url = `/${response.output.account_id}/contracts/${response.output.contract_id}`
            const params = `?template_dashboard=1`
            window.location.href = url + params
        }
    }

    stopProgressBar() {
        if (this.progressCheck) {
            clearInterval(this.progressCheck)
        }
    }
}
