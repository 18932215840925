import {Controller} from "stimulus"

function processBadgeFlags(flags) {
    const cards = this.badgeTargets
    const header = this.hasHeaderTarget ? this.headerTarget : null

    cards.forEach(function (card, index) {
        const id = card.dataset.badgeId
        if (flags[id]) {
            card.dataset.showBadge = 1
        }
    })

    if (flags.flash) {
        if (flags.notification_override || !header) {
            window.showFlashNotification(flags.flash)
        } else {
            header.dataset.showNotification = 1
        }
    }

    if (flags.welcome) {
        _.delay(function() {
            window.showDialogHTMLContent(flags.welcome)
        }, 2000)
    }

    this.welcome_badges = flags.welcome_badges ?? null
}

export default class extends Controller {
    static targets = ['badge', 'group', 'header']

    connect() {
        const groups = this.groupTargets

        groups.forEach((settingsGroup) => {
            const url = settingsGroup.dataset.badgeUrl
            var data = null

            if (this.data.get('page')) {
                data = 'page=' + this.data.get('page')
            }

            ujs.ajax({
                url: url,
                type: 'get',
                data: data,
                dataType: 'script',
                success: (response) => {
                    processBadgeFlags.call(this, response)
                }
            })
        })
    }

    show_welcome_badges() {
        window.showDialogHTMLContent(this.welcome_badges)
    }

    show_tooltip(ev) {
        ev.preventDefault()
        ev.stopPropagation()
    }
}
