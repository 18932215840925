import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = [ 'section' ]

    visit(ev) {
        if (this.data.get('enabled') == "1") {
            ev.currentTarget.dataset.remote = "1"
            this.selectSiteAudit(ev.currentTarget.closest('[data-site-audit-id]'))
        } else {
            delete ev.currentTarget.dataset.remote
        }
    }

    selectSiteAudit(element) {
        if (!element) {
            return
        }
        let parent = element.parentNode
        if (!parent) {
            return
        }

        parent.querySelectorAll('[data-site-audit-id]').forEach((el) => {
            if (el == element) {
                el.dataset.selected = "1"
                parent.dataset.selected = "1"
            } else {
                delete el.dataset.selected
                delete parent.dataset.selected
            }
        })
    }

    deselectSiteAudit(ev) {
        let id = ev.currentTarget.dataset.siteAuditId
        if (id) {
            this.deselectSiteAuditById(id)
        }
    }

    deselectSiteAuditById(id) {
        let element = this.element.querySelector('[data-site-audit-id="' + id + '"]')
        if (element) {
            delete element.dataset.selected
            delete element.parentElement.dataset.selected
            let card = element.closest('[data-role~="drill-activity-card"]')
            if (card) {
                let view = card.querySelector('[data-role~="drill-site-audit-view"][data-site-audit-id="' + id + '"]')
                if (view) {
                    view.innerHTML = ''
                }
            }
        }
    }
}

