export default {

    beforeDraw: function(chart) {
        if (chart.config.options.breakdownChart) {
            let config = chart.config.options.breakdownChart
            let popout = config.popOutSize || 5

            if (chart.active && chart.active.length > 0) {
                let meta = chart.getDatasetMeta(chart.active[0]._datasetIndex)
                let view = meta.data[chart.active[0]._index]._view
                view.outerRadius += popout
            }
        }
    }
}
