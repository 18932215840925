import { Controller } from "stimulus"

function loadForest(segmentName) {
    let url = this.data.get('url')

    ujs.ajax({
        url: url,
        type: 'get',
        success: response => {
            this.forestData = {data: response, segment: segmentName}
        }
    })
}

function loadDocuments(segmentName, parentId) {
    let url = this.data.get('url') + '&loadDocuments=true&parentId=' + parentId

    ujs.ajax({
        url: url,
        type: 'get',
        success: response => {
            this.forestData = {data: response, segment: segmentName}
        }
    })
}

export default class extends Controller {
    static targets = ['data']

    connect() {
        loadForest.call(this, false)
    }

    loadDocumentData(ev) {
        if(!ev)
            return

        let loadDocumentList = ev.detail.load_document_list
        let parentId = ev.detail.parent_id
        if(loadDocumentList)
            loadDocuments.call(this, 'file', parentId)
    }

    set forestData(parms) {
        this.dataTarget.dispatchEvent(new CustomEvent('update', { detail: parms }))
        window.closeDialogs()
    }
}
