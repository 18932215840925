import { Controller } from "stimulus"

export default class extends Controller {

    connect() {
        this.element.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                if (e.target.nodeName == 'INPUT' && e.target.type == 'text') {
                    e.preventDefault();
                    return false;
                }
            }
        }, true);
    }
}
