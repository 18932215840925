import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
    }

    showHint(ev) {
        this.data.set('showHint', 1)
    }

    dismiss(ev) {
        ev.preventDefault()
        ev.stopPropagation()

        // ajax to clear flag
        this.data.set('showHint', 0)
        this.data.set('hasHint', 0)
        this.forgetHint()
    }

    forgetHint() {
        let url = this.data.get('url')
        ujs.ajax({
            url: url,
            type: 'delete',
            dataType: 'json'
        })
    }
}
