import {Controller} from "stimulus"

const COLLAPSED_DATA_TAG = 'collapsed'

export default class extends Controller {
    static targets = ['expandable']

    toggleSection(evOrRef) {
        var refid

        if (evOrRef instanceof Event) {
            evOrRef.preventDefault()
            refid = evOrRef.currentTarget.dataset.sectionRef
        } else {
            refid = evOrRef
        }

        if (refid) {
            let el = document.querySelector('[data-id="' +  refid + '"]')
            let isCollapsed = el ? (el.dataset[COLLAPSED_DATA_TAG] == "true") : false

            isCollapsed ? this.openSectionElement(el) : this.closeSectionElement(el)
        }
    }

    openSectionElement(el) {
        el && (el.dataset[COLLAPSED_DATA_TAG] = "false")
    }

    closeSectionElement(el) {
        el && (el.dataset[COLLAPSED_DATA_TAG] = "true")
    }

    closeAllSections() {
        this.sectionTargets.forEach(function(el) {
            this.closeSectionElement(el)
        })
    }
}
