import {Controller} from "stimulus"

export default class extends Controller {

    static targets = [ "crewHoursRecord" ]

    addHole(ev) {
        ujs.ajax({
            url: ev.currentTarget.dataset.addUrl,
            type: 'get',
            dataType: 'script'
        })
    }

    createHole(ev) {
        ev.preventDefault()
        this.addHole(ev)
    }

    updateHoles(ev) {
        ev.preventDefault()
        this.addHole(ev)
    }

    addCrewHours(ev) {
        ev.preventDefault()
        const currentRecords = this.crewHoursRecordTargets

        // Build up crew records in params so we know which people to show in
        // the dropdown.
        let params = ''
        currentRecords.forEach((record, i) => {
            params += `crew_hours_record[${i}]=${record.dataset.comboId}&`
        })
        if (params !== '') {
            params = "?" + params
        }
        this.editRecords(ev, params)
    }

    addMaterialRecords(ev) {
        ev.preventDefault()
        this.editRecords(ev)
    }

    addServiceRecords(ev) {
        ev.preventDefault()
        this.editRecords(ev)
    }

    addTestRecords(ev) {
        ev.preventDefault()
        this.editRecords(ev)
    }

    editRecords(ev, params = '') {
        ujs.ajax({
            url: ev.currentTarget.dataset.editUrl + params,
            type: 'get',
            dataType: 'script'
        })
    }

    deleteRecords(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        if (confirm(ev.currentTarget.dataset.confirmMessage)) {
            ujs.ajax({
                url: ev.currentTarget.dataset.deleteUrl,
                type: 'post',
                data: {},
                dataType: 'script'
            })
        }
    }

    deleteRecordsForHole(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        if (confirm(ev.currentTarget.dataset.confirmMessage)) {
            ujs.ajax({
                url: ev.currentTarget.dataset.deleteUrl,
                type: 'post',
                data: new URLSearchParams({hole_id: ev.currentTarget.dataset.holeId}).toString(),
                dataType: 'script'
            })
        }
    }

    deleteTimesheet(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        if (confirm(ev.currentTarget.dataset.confirmMessage)) {
            ujs.ajax({
                url: ev.currentTarget.dataset.deleteUrl,
                type: 'delete',
                dataType: 'script',
                error: () => {
                    alert('Unauthorized request: You cannot delete a timesheet in this state.')
                }
            })
        }
    }

    addDrillingRecords(ev) {
        ev.preventDefault()
        // Apparently you can't check for last child with class. Since there is
        // another <li> for adding a new record, we need to get the second to
        // last child.
        let params = ''
        let hole = ev.currentTarget.closest('li.list__button').dataset.holeId
        let lastRecord = document.querySelector(`.grouped-hole-records[data-id="hole-records-${hole}"] .drilling-records-edit-list li:nth-last-child(2)`)
        if (lastRecord.classList.contains('headings')) {
            params = '&first_record=true'
        } else {
            let endM = lastRecord.dataset.endM
            let coreId = lastRecord.dataset.coreId
            params = `&previous_end_m=${endM}&previous_core_id=${coreId}`
        }
        this.editRecords(ev, params)
    }

    editDrillingRecords(ev) {
        // Apparently you can't check for last child with class. Since there is
        // another <li> for adding a new record, we need to get the second to
        // last child.
        let params = ''
        const item = ev.currentTarget
        const previousItem = getPreviousSibling(item, '[data-id]')
        const nextItem = getNextSibling(item, '[data-id]')
        if (previousItem) {
            params = `&previous_end_m=${previousItem.dataset.endM}`
        } else {
            params = '&first_record=true'
        }
        if (nextItem) {
            params += `&next_start_m=${nextItem.dataset.startM}`
        }
        this.editRecords(ev, params)
    }

    deleteDrillingRecords(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        const formData = this.getFieldsets()
        if (confirm(ev.currentTarget.dataset.confirmMessage)) {
            ujs.ajax({
                url: ev.currentTarget.dataset.deleteUrl,
                type: 'post',
                data: formData,
                dataType: 'script'
            })
        }
    }

    getFieldsets() {
        const form = document.getElementById('wizard-form')
        return new FormData(form)
    }
}
