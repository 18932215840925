import Utils from './utils'

function textToDisplay(config) {
    var lines = [null, null]

    if (this.active && this.active.length > 0 && Array.isArray(config.dataTitles)) {
        let index = this.active[0]._index
        lines = [config.dataTitles[index]]
        if (config.dataSubTitles) {
            lines.push(config.dataSubTitles[index])
        }
    } else {
        lines = [config.text, config.subText]
    }

    return lines
}

function activeDatasetColor(config) {
    if (this.active && this.active.length > 0 && config.changeTextColor) {
        let meta = this.getDatasetMeta(this.active[0]._datasetIndex)
        return meta.data[this.active[0]._index]._options.backgroundColor
    }
}

export default {
    afterDraw: function (chart, options) {
        if (chart.config.options.elements.donutCenterText) {
            let config = chart.config.options.elements.donutCenterText

            let lines = textToDisplay.call(chart, config)

            let fontStyle = config.fontStyle || 'teko'
            let fontStyleSub = config.fontStyleSub || 'ubuntu'
            let baseFontSize = config.baseFontSize || 30
            let sizingText = config.sizingText || 'XXXM'
            let sizingFactor = config.sizingFactor || 0.95
            let fontSizeToUse = Utils.fontSizeToFit(chart, sizingText, sizingFactor * chart.innerRadius, baseFontSize, fontStyle)

            let ctx = chart.ctx
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.font = fontSizeToUse + "px " + fontStyle
            ctx.fillStyle = config.color || 'white'

            let center = Utils.centerOfChartArea(chart)
            if (lines[0]) {
                ctx.fillStyle = activeDatasetColor.call(chart, config)
                ctx.fillText(lines[0], center.x, center.y)
            }
            if (lines[1]) {
                ctx.textBaseline = 'top'
                ctx.fillStyle = config.color || 'white'
                ctx.font = (0.5 * fontSizeToUse) + "px " + fontStyleSub
                ctx.fillText(lines[1], center.x, center.y + fontSizeToUse/2)
            }
        }
    }
}
