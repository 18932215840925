import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['container', 'autoOpen']

    connect() {
        if (this.hasAutoOpenTarget) {
            const node = this.autoOpenTarget
            const str = node.dataset.modalContent
            const after = node.dataset.modalAfter ?? 2000
            node.parentNode.removeChild(node)
            this.autoOpenFromString(str, after)
        }
    }

    openNode(node) {
        this.containerTarget.innerHTML = ''
        this.containerTarget.appendChild(node)
        this.containerTarget.dataset.modalActive = "true"
        this.element.dataset.modalShowing = "true"
        if (node.dataset && node.dataset.presentationMode) {
            this.element.dataset.modalPresentationMode = node.dataset.presentationMode
        }
    }

    openFromSelector(ev) {
        ev.preventDefault()
        const str = ev.target.dataset.modalContent
        this.openFromString(str)
    }

    openFromString(str) {
        if (str) {
            let node = convertHTMLToNode(str)
            this.openNode(node)
        }
    }

    autoOpenFromString(str, delayMillis) {
        _.delay(() => {
            this.openFromString(str)
        }, delayMillis)
    }

    close(ev) {
        if (ev && ev.currentTarget.dataset.closeOverrideUrl) {
            window.location.replace(ev.currentTarget.dataset.closeOverrideUrl)
        }

        if (ev && ev.target.closest('.modal') != null && (ev.keyCode == 27 || ev.type == 'click')) {
            ev.preventDefault()
        }

        delete this.containerTarget.dataset.modalActive
        delete this.element.dataset.modalShowing
        if (this.element.dataset.modalPresentationMode) {
            delete this.element.dataset.modalPresentationMode
        }
        this.containerTarget.innerHTML = ''
    }
}
