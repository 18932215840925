import { Controller } from "stimulus"
import { relativeTimeThreshold } from "moment";

function emptyContainers() {
    this.reportContainerTargets.forEach((el) => {
        el.innerHTML = ''
    })
}

function selectTab(withId) {
    this.reportTabTargets.forEach((el) => {
        if (el.dataset.reportRefId == withId) {
            el.dataset.selected = "1"
        } else if (el.dataset.selected) {
            delete el.dataset.selected
        }
    })
    this.reportContainerTargets.forEach((el) => {
        if (el.dataset.reportId == withId) {
            el.dataset.shown = "1"
        } else if (el.dataset.shown) {
            delete el.dataset.shown
        }
    })
}

function containerFor(id) {
    var container = null
    this.reportContainerTargets.forEach((el) => {
        if (el.dataset.reportId == id)
            container = el
    })

    return container
}

function isReportLoaded(id) {
    let container = containerFor.call(this, id)
    return container && (container.dataset.loaded == "1")
}

function getSelectedReport() {
    var element = document.body.querySelector('.report-tab-collection li[data-selected="1"]')
    return element.getAttribute('data-report-ref-id')
}

export default class extends Controller {
    static targets = ['reportContainer', 'reportTab', 'applyNewFilters', 'financialSummaryChart']

    connect() {
        if (this.data.get('initial-report')) {
            _.delay(() => {
                this.element.querySelector('[data-report-ref-id="' + this.data.get('initial-report') + '"] button').click()
            })
        }
    }

    applyNewFilters() {
        var report = getSelectedReport.call(this)
        if (report) {
            document.getElementById(report).click()
        }
    }

    updateFinancialSummary() {
        this.financialSummaryChartTargets.forEach((chart) => {
            const controller =  this.application.getControllerForElementAndIdentifier(chart, "chart")
            controller.reload()
        })
    }

    showReport(ev) {
        let tab = ev.target.closest('[data-report-ref-id]')
        let id = tab && tab.dataset.reportRefId
        if (id) {
            this.report = id
            if (!isReportLoaded.call(this, id)) {
                // let event propagate and submit form
                return
            }
        }

        // otherwise, we're done here
        ujs.stopEverything(ev)
    }

    export(ev) {
        var formData = this.getFieldsets(ev);
        var query = new URLSearchParams(formData).toString();
        window.location.href = ev.currentTarget.dataset.url + '?' + query;
    }

    getFieldsets() {
        var formData = new FormData(document.getElementById('report-filters'))
        return formData;
    }

    set report(id) {
        selectTab.call(this, id)
    }
}
