let ujs = require('@rails/ujs')

let timeIntervalInputSelector = '[data-behavior~="interval-entry"]'

function pad(n, width) {
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}

var convertToInterval = function(ev) {
    let current = ev.target.value ?? null
    if (current != null) {
        if (current.match(/[0-9]{4}/)) {
            ev.target.value = current.substring(0,2) + ":" + current.substring(2,4)
        } else if (current.match(/[0-9.]+/)) {
            let hoursFloat = parseFloat(current)
            let hours = Math.floor(hoursFloat)
            let minutes = Math.floor((hoursFloat-hours)*60)
            let formatted = pad(hours,2) + ":" + pad(minutes, 2)
            ev.target.value = formatted
        }
    }
}

ujs.delegate(document, timeIntervalInputSelector, "focusout", convertToInterval);
